import * as React from 'react';
import { useState, useEffect, createRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import GooglePlaces from 'src/components/GooglePlaces';
import Slider from '@mui/material/Slider';
import { ChangePropertyModal, ReservationListModal } from '../sections/@dashboard/user';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Box,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  CircularProgress,
  Drawer,
  IconButton,
  Pagination,
  Rating,
  MenuItem,
  CardContent,
  TextField,
  Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PropertyDetails } from '../sections/@dashboard/products';
import pallete from '../theme/palette';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';
import { api } from '../Api';
import { Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export default function Reservations() {
  const { t } = useTranslation();
  const TABLE_HEAD = [
    { id: 'address', label: t('Property.table.1'), alignRight: false },
    { id: 'price', label: t('Property.table.2'), alignRight: false },
    { id: 'user', label: 'Owner', alignRight: false },
    { id: 'start_date', label: 'Start Date', alignRight: false },
    { id: 'end_date', label: 'End Date', alignRight: false },
    // { id: 'Transactions', label: 'Transactions', alignRight: false },
    { id: 'Status', label: 'Status', alignRight: false },
    { id: 'action', label: t('Property.table.6'), alignRight: false }
  ];

  const requirement = ['visible', 'delete', 'change status'];
  const [permission, setpermission] = useState([]);
  const [list, setlist] = useState([]);
  const [detail, setDetail] = useState(false);
  const [loading, setloading] = useState(true);
  const [alertSuccess, setalertSuccess] = useState({ visible: false, message: '' });
  const [alert, setalert] = useState({ visible: false, message: '' });
  const [selectedStatus, setSelectedStatus] = useState('');
  const [statusList, setStatusList] = useState([]);
  const [selectedIndex, setselectedIndex] = useState(null);
  const [loader, setLoader] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [business, setbusiness] = useState(null);
  const [search, setsearch] = useState('');
  const [searchLoading, setsearchLoading] = useState(false);
  const [statusss, setStatus] = useState(null);
  const [address, setaddress] = useState(null);
  const tableRef = createRef();
  const [user, setuser] = useState(null);
  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = useState(1);
  const [statusModal, setstatusModal] = useState(false);

  const handleOpenStatus = () => setOpenStatus(true);
  const handleCloseStatus = () => { setOpenStatus(false); setStatus(null) }

  const isBusinessFound = list.length === 0;

  useEffect(() => {
    checkRender();
  }, []);

  useEffect(() => {
    if (user) {
      const selected = statusList?.filter((e) => e.name.toLowerCase() === user?.status.toLowerCase())
      setSelectedStatus(selected[0])
    }
  }, [user]);

  useEffect(() => {
    getProperties();
    getUserStatus()
  }, []);

  useEffect(() => {
    if (search.length == 0) {
      list.length = 0;
      getProperties();
    }
  }, [search]);

  useEffect(() => {
    list.length = 0;
    getProperties();
  }, [address]);


  function getProperties(pagenumber) {
    setloading(true);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();

    formdata.append('host', 'admin');
    pagenumber && formdata.append('page', Encryption(pagenumber, process.env.REACT_APP_SECRET_APP));

    search.length > 0 &&
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_APP));

    if (address && address.lat && address.lon) {
      formdata.append('lat', address?.lat);
      formdata.append('lon', address?.lon);
      address?.lat && address?.lon && formdata.append('radius', '25000');
    }

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.get_reservation_history, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);

        if (result.success === true) {
          result?.count ? setpagesCount(Math.ceil(result?.count / 10)) : setpagesCount(1);

          setlist(result.data);
        } else {
          setlist([]);
          setpagesCount(0);
        }
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setsearchLoading(false);
        setloading(false);
      });
  }

  function handleChangeStatus(status) {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();

    formdata.append('reservation_id', user.id);
    formdata.append('status', status.name);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    setloading(true);
    fetch(api.reservation_status_change, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const response = JSON.parse(result);
        if (response.success === true) {
          refreshAdmin();
          handleCloseStatus()
          setalertSuccess({ visible: true, message: response.message });
          setTimeout(() => {
            setalertSuccess({ visible: false, message: response.message });
          }, 2000);
        } else {
          setalert({ visible: true, message: 'Something went wrong' });
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => { setloading(false); handleCloseStatus() });
  }

  function Delete_Reservation(reservation_id) {
    setLoader(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('id', reservation_id);
    formdata.append('host', 'admin');

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.delete_reservation, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          refreshAdmin();
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoader(false);
        setselectedIndex(null);
      });
  }

  function getUserStatus() {
    setloading(true);
    const myHeaders = new Headers();

    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append('type', "reservation");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.status, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          setStatusList(result.data);
        } else {
          setStatusList([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
      });
  }

  const detailClose = () => {
    setDetail(false);
  };

  function checkRender() {
    let check = 'properties';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );
    localPerm[0].permission_types.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  const refresh = () => {
    setDetail(false);
    getProperties(page);
  };

  function openModal(row) {
    setbusiness(row);
    setDetail(true);
  }

  function closeandOpen() {
    setDetail(false);
  }

  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    getProperties(value);
  };

  function openStatusModal(row) {
    setbusiness(row);
    setstatusModal(true);
  }

  function openModalStatus(user) {
    setuser(user);
    handleOpenStatus();
  }

  const refreshAdmin = () => {
    setstatusModal(false);
    getProperties(page);
  };

  const handleChangeSattus = (event) => {
    setStatus(event.target.value);
    handleChangeStatus(event.target.value)
  };

  const style = {
    display: 'flex',
    height: 'auto',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const boxStyle = {
    minWidth: '70%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    border: '2px solid',
    borderColor: pallete.primary.main
  };

  const boxStyle2 = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    maxWidth: '40%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    p: 4,
    border: '2px solid',
    borderColor: 'primary.main'
  };

  return (
    <Page title={`Reservations | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>

        {/* PROPERTY DETAIL MODAL */}
        <Modal style={style} open={detail} onClose={detailClose}>
          <Box
            sx={{ ...boxStyle, p: 0, minWidth: '70%', minHeight: '80%' }}
            className="admin_modal_pa"
          >
            {/* PROPERTY DETAIL */}
            <PropertyDetails
              handleClose={detailClose}
              details={business}
              refresh={refresh}
              closeandOpen={closeandOpen}
            />
          </Box>
        </Modal>

        {/* RESERVATION DETAIL MODAL */}
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'auto',
            width: '100%'
          }}
          open={statusModal}
          onClose={() => {
            setstatusModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle, p: 0, minWidth: '70%' }} className="admin_modal_pa">
            <ReservationListModal
              data={business?.recurring_payments}
              handleClose={() => {
                setstatusModal(false);
              }}
            />
          </Box>
        </Modal>

        {/* CHANGE STATUS MODAL */}
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
          open={openStatus}
          onClose={handleCloseStatus}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle2, style }} className="admin_modal_pa">

            <Box p={3} flexDirection={'column'} display={'flex'} flex={1}>
              <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Change Status
              </Typography>
              <CardContent>

                <TextField
                  style={{
                    width: '100%',
                  }}
                  select
                  value={selectedStatus ? selectedStatus : statusss}
                  label={"Status"}
                  onChange={handleChangeSattus}
                  className="width_full_status"
                >
                  {statusList.map((option) => (
                    <MenuItem key={option.id} value={option}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </CardContent>
              {alert && alert.visible && <Alert severity="error">{alert.message}</Alert>}
              <Button style={{ marginTop: 10 }} color="primary" variant="outlined" onClick={handleCloseStatus}>
                Close
              </Button>
            </Box>
          </Box>
        </Modal>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Reservations
          </Typography>
        </Stack>

        {alertSuccess && alertSuccess.visible && <Alert style={{ marginBottom: 20 }} severity="success">{alertSuccess.message}</Alert>}

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between'
            }}
            className="top_options"
          >
            <Searchbar
              width={'55%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              placeholder={'Search' + ' by user'}
              onEnter={() => {
                setsearchLoading(true);
                getProperties();
              }}
            />

            {/* GOOGLE PLACES */}
            <GooglePlaces
              width={'30%'}
              onResult={(val) => {
                setaddress(val);
              }}
              regions={false}
              placeholder={t('Property.loc')}
            />

            <LoadingButton
              style={{ width: '12%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                setPage(1);
                getProperties(1);
              }}
              className="width_full"
            >
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table ref={tableRef}>
                <UserListHead headLabel={TABLE_HEAD} rowCount={list.length} />
                <TableBody>
                  {list.map((row, index) => {
                    const {
                      listing_id,
                      id,
                      seen,
                      price,
                      tenure,
                      start_date,
                      end_date,
                      recurring_payments,
                      is_cancelable,
                      user_name,
                      status
                    } = row;

                    return (
                      <TableRow
                        style={{
                          cursor: 'pointer',
                          backgroundColor: seen == '0' && 'rgba(253,208,151,0.2)'
                        }}
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell scope="row" padding="normal" style={{ minWidth: 200 }}>
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Avatar
                              alt={row.title}
                              src={
                                listing_id?.images?.length > 0
                                  ? listing_id?.images[0]?.uri
                                  : 'https://api.pomillions.com/public/storage/business/businessplaceholder.png'
                              }
                            />
                            <div className="one-line-ellipsis">{listing_id?.location}</div>
                          </Stack>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: 100 }}>
                          <Typography variant="subtitle2" style={{ fontSize: 12 }}>
                            {'ZAR'} {price}
                          </Typography>
                        </TableCell>

                        {/* <TableCell align="left">
                          <Typography variant="subtitle2" style={{ fontSize: 12 }}>
                            {tenure}
                          </Typography>
                        </TableCell> */}

                        <TableCell align="left" style={{ minWidth: 140 }}>
                          <Typography variant="subtitle2" style={{ fontSize: 12 }}>
                            {user_name}
                          </Typography>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: 110 }}>
                          <Typography variant="subtitle2" style={{ fontSize: 12 }}>
                            {moment(start_date).format("DD MMM YYYY")}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: 110 }}>
                          <Typography variant="subtitle2" style={{ fontSize: 12 }}>
                            {moment(end_date).format("DD MMM YYYY")}
                          </Typography>
                        </TableCell>


                        <TableCell align="left" style={{ maxWidth: 130 }}>
                          <Label
                            style={{ height: 30, paddingLeft: 30, paddingRight: 30, width: 110 }}
                            variant="ghost"
                            color={
                              (status.toLowerCase() === 'confirmed' && 'success') ||
                              (status.toLowerCase() === 'completed' && 'info') ||
                              (status.toLowerCase() === 'cancelled' && 'error') ||
                              (status.toLowerCase() === 'active' && 'success') ||
                              'error'
                            }
                          >
                            <Typography
                              style={{
                                textTransform: 'capitalize',
                                fontWeight: 'bold',
                                fontSize: 12
                              }}
                            >
                              {status}
                            </Typography>
                          </Label>
                        </TableCell>

                        {/* ACTIONS */}
                        <TableCell style={{ position: 'relative', minHeight: 400, width: '200px' }}>
                          <Box
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start'
                            }}
                          >
                            {permission.includes('visible') && (
                              <>
                                <Button
                                  startIcon={
                                    <Iconify color={pallete.primary.main} icon="gg:notes" />
                                  }
                                  onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    openModal(listing_id);
                                  }}
                                  variant="outlined"
                                  size="small"
                                  color="primary"
                                  component={RouterLink}
                                  to="#"
                                  style={{
                                    width: 175,
                                    justifyContent: 'flex-start',
                                    marginTop: 5
                                  }}
                                >
                                  Property Details
                                </Button>
                                <Button
                                  size="small"
                                  onClick={() => openStatusModal(row)}
                                  style={{
                                    justifyContent: 'flex-start',
                                    marginTop: 5,
                                    width: 175
                                  }}
                                  variant="outlined"
                                  component={RouterLink}
                                  to="#"
                                  startIcon={
                                    <Iconify
                                      color={pallete.primary.main}
                                      icon="mdi:swap-horizontal-bold"
                                    />
                                  }
                                >
                                  Reservation Details
                                </Button>
                              </>
                            )}

                            {permission.includes('change status') && (
                              <Button
                                size="small"
                                onClick={() => openModalStatus(row)}
                                style={{
                                  justifyContent: 'flex-start',
                                  marginTop: 5,
                                  width: 175,
                                  alignSelf: 'center',
                                  marginRight: 10
                                }}
                                variant="outlined"
                                component={RouterLink}
                                to="#"
                                startIcon={
                                  <Iconify
                                    color={pallete.primary.main}
                                    icon="bx:edit"
                                  />
                                }
                              >
                                change status
                              </Button>
                            )}

                            {is_cancelable && permission.includes('delete') && (
                              <Button
                                startIcon={
                                  loader && selectedIndex == index ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    <Iconify color={pallete.primary.main} icon="ion:trash" />
                                  )
                                }
                                onClick={(event) => {
                                  setLoader(true);
                                  setselectedIndex(index);
                                  Delete_Reservation(id);
                                }}
                                variant="outlined"
                                size="small"
                                color="primary"
                                component={RouterLink}
                                to="#"
                                style={{
                                  width: 175,
                                  justifyContent: 'flex-start',
                                  marginTop: 5
                                }}
                              >
                                Delete Reservation
                              </Button>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isBusinessFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={search} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        {!loading && !searchLoading && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
