import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Button
} from '@mui/material';
import Label from 'src/components/Label';
import moment from 'moment';

const RecurringPaymentsTable = ({ data, handleClose }) => {
  return (
    <Box p={3} flexDirection={'column'} display={'flex'}>
      <Box
        flexDirection={'row'}
        display={'flex'}
        flex={1}
        justifyContent={'space-between'}
        px={3}
        mb={2}
      >
        <Typography variant="h4">Reservation Details</Typography>
        <Button color="primary" variant="contained" onClick={handleClose} size={'small'}>
          Close
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ px: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Ref No</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Tenure</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((payment) => (
              <TableRow key={payment.id}>
                <TableCell>{payment.id}</TableCell>
                <TableCell>{payment.payment_token}</TableCell>
                <TableCell>ZAR {payment.sub_total}</TableCell>
                <TableCell>{payment.tenure}</TableCell>
                <TableCell>{ }
                  {moment(payment.start_date).format("DD MMM YYYY")}
                </TableCell>
                <TableCell>
                  {moment(payment.end_date).format("DD MMM YYYY")}
                </TableCell>
                <TableCell>
                  {' '}
                  <Label
                    style={{ height: 30, paddingLeft: 30, paddingRight: 30, width: 130 }}
                    variant="ghost"
                    color={
                      payment?.status === 'paid'
                        ? 'success'
                        : payment?.status == 'cancelled'
                          ? 'error'
                          : 'info'
                    }
                  >
                    <Typography
                      style={{
                        textTransform: 'capitalize',
                        fontWeight: 'bold',
                        fontSize: 12
                      }}
                    >
                      {payment?.status}
                    </Typography>
                  </Label>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RecurringPaymentsTable;
