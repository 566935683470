import * as React from 'react';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Box,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
  Pagination
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';
import { api } from '../Api';
import { Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export default function Transactions() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { id: 'user', label: 'User', alignRight: false },
    { id: 'ref', label: 'Reference No', alignRight: false },
    { id: 'start_date', label: 'Start Date', alignRight: false },
    { id: 'end_date', label: 'End Date', alignRight: false },
    { id: 'total', label: 'Total', alignRight: false },
    { id: 'tenure', label: 'Tenure', alignRight: false }
  ];

  const [list, setlist] = useState([]);
  const [loading, setloading] = useState(true);

  function getTransaction(pagenumber) {
    setloading(true);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();

    formdata.append('host', 'admin');
    pagenumber && formdata.append('page', Encryption(pagenumber, process.env.REACT_APP_SECRET_APP));

    search.length > 0 &&
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_APP));

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.get_transactions, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);

        if (result.success === true) {
          result?.count ? setpagesCount(Math.ceil(result?.count / 10)) : setpagesCount(1);

          setlist(result.data);
        } else {
          setlist([]);
          setpagesCount(0);
        }
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setsearchLoading(false);
        setloading(false);
      });
  }

  useEffect(() => {
    getTransaction();
  }, []);

  const isBusinessFound = list.length === 0;

  const [search, setsearch] = useState('');
  const [searchLoading, setsearchLoading] = useState(false);

  const tableRef = React.createRef();

  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);

  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    getTransaction(value);
  };

  useEffect(() => {
    if (search.length == 0) {
      getTransaction();
    }
  }, [search]);

  return (
    <Page title={`Transactions | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Transactions
          </Typography>
        </Stack>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between'
            }}
            className="top_options"
          >
            <Searchbar
              width={'87%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              placeholder={t('Agents.search') + '...'}
              onEnter={() => {
                setsearchLoading(true);
                setPage(1);
                getTransaction(1);
              }}
            />

            <LoadingButton
              style={{ width: '12%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                setPage(1);
                getTransaction(1);
              }}
              className="width_full"
            >
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table ref={tableRef}>
                <UserListHead headLabel={TABLE_HEAD} rowCount={list.length} />
                <TableBody>
                  {list.map((row, index) => {
                    const { id, total, tenure, start_date, end_date, user, payment_token } = row;

                    return (
                      <TableRow
                        style={{
                          cursor: 'pointer'
                        }}
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell scope="row" padding="normal" style={{ minWidth: 200 }}>
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Avatar alt={row.title} src={user?.avatar} />
                            <div className="one-line-ellipsis">{user?.name}</div>
                          </Stack>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: 100 }}>
                          <Typography variant="subtitle2" style={{ fontSize: 12 }}>
                            {payment_token}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="subtitle2" style={{ fontSize: 12 }}>
                            {moment(start_date).format("DD MMM YYYY")}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="subtitle2" style={{ fontSize: 12 }}>
                            {moment(end_date).format("DD MMM YYYY")}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="subtitle2" style={{ fontSize: 12 }}>
                            ZAR {total}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="subtitle2" style={{ fontSize: 12 }}>
                            {tenure}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isBusinessFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={search} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        {!loading && !searchLoading && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
