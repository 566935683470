import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import OtpInput from 'react-otp-input';
import { useTheme } from '@emotion/react';

// ----------------------------------------------------------------------//

export default function OtpForm() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [alert, setalert] = useState({ visible: false, message: '', color: 'success' });
  const [loading, setloading] = useState(false);

  const otpInputStyle = {
    width: '100%',
    height: '56px',
    textAlign: 'center',
    fontSize: '18px',
    borderRadius: '4px',
    boxSizing: 'border-box',
    padding: '8px',
    border: `1px solid ${theme.palette.primary.main} !important`
  };

  const otpContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  };

  const codeCheck = localStorage.getItem('code');

  return (
    <Stack spacing={2}>
      {alert.visible && (
        <Alert
          onClose={() => {
            setalert({ visible: false, message: '', color: 'success' });
          }}
          severity={alert?.color}
        >
          {alert.message}
        </Alert>
      )}
      <div style={otpContainerStyle}>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={5}
          renderSeparator={<span>&nbsp;</span>}
          renderInput={(props) => <input {...props} style={otpInputStyle} />}
        />
      </div>
      <LoadingButton
        onClick={() => {
          if (otp == codeCheck) {
            navigate('/changePassword', { replace: true });
          } else {
            setalert({
              visible: true,
              message: 'Invalid Code. Please check again',
              color: 'error'
            });
          }
        }}
        fullWidth
        size="large"
        variant="contained"
        loading={loading}
      >
        Continue
      </LoadingButton>
    </Stack>
  );
}
