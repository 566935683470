import { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Snackbar
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import { api } from '../../../Api';
import Iconify from '../../../components/Iconify';
import { Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';

export default function UserPassword({ handleClose }) {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    oldpassword: '',
    password: '',
    confirm: ''
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [alert, setalert] = useState({ visible: true, message: '' });
  const [issubmiting, setissubmiting] = useState(false);

  const resetFunction = () => {
    setissubmiting(true);

    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();

    formdata.append(
      'oldpassword',
      Encryption(values.oldpassword, process.env.REACT_APP_SECRET_KEY)
    );
    formdata.append('newpassword', Encryption(values.password, process.env.REACT_APP_SECRET_KEY));
    formdata.append(
      'confirmpassword',
      Encryption(values.confirm, process.env.REACT_APP_SECRET_KEY)
    );

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.reset_password, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);

        if (result.success === true) {
          setalert({ visible: true, message: result.message });
          setShowSnackbar(true);
          //
        } else {
          setalert({ visible: true, message: result.message });
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setissubmiting(false);
      });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setShowSnackbar(false);
          handleClose();
        }}
      >
        <Alert
          onClose={() => {
            setShowSnackbar(false);
          }}
          severity="success"
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Box px={4} pt={6} pb={2}>
        <form>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="h4" gutterBottom>
              {t('ChangePassword.name')}
            </Typography>
          </Box>

          <TextField
            fullWidth
            label={t('ChangePassword.old')}
            margin="normal"
            name="oldpassword"
            onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            value={values.oldpassword}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <TextField
            fullWidth
            label={t('ChangePassword.new')}
            margin="normal"
            name="password"
            onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            value={values.password}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <TextField
            fullWidth
            label={t('ChangePassword.confirm')}
            margin="normal"
            name="confirm"
            onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            value={values.confirm}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          {alert && !alert.message.includes('success') && alert.message != '' && (
            <Alert style={{ marginTop: 10 }} severity="error">
              {alert.message}
            </Alert>
          )}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              py: 2
            }}
          >
            <LoadingButton
              sx={{
                marginRight: 1
              }}
              onClick={resetFunction}
              color="primary"
              variant="contained"
              loading={issubmiting}
            >
              {t('ChangePassword.buttons.0')}
            </LoadingButton>

            <Button onClick={handleClose} variant="outlined">
              {t('ChangePassword.buttons.1')}
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
}
