import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Login from './pages/Login';
import Properties from './pages/Properties';
import Rolepermission from './pages/Rolepermission';
import User from './pages/User';
import NotFound from './pages/Page404';
import Requests from './pages/Inquiry';
import Forgot from './pages/Forgot';
import ChangePassword from './pages/ChangePassword';
import OtpVerification from './pages/OtpVerification';
import Reservations from './pages/Reservations';
import Transactions from './pages/Transactions';
import Feedback from './pages/Feedback';
import Bugs from './pages/Bugs';
import Faqs from './pages/Faqs';
import FaqCategories from './pages/FaqCategories';
import Wallet from './pages/Wallet';
import KycVerification from './pages/KycVerification';

export default function Router() {
  const localPerm = JSON.parse(localStorage.getItem('permissions'));
  const token = localStorage.getItem('token');

  return useRoutes([
    {
      path: '/',
      element: token ? <Navigate to="/dashboard/roles" /> : <LogoOnlyLayout />,
      children: [
        { path: 'app', element: <Navigate to="/dashboard/app" /> },
        { path: '/', element: <Navigate to="/login" /> },
        { path: 'login', element: <Login /> },
        { path: 'forgot', element: <Forgot /> },
        { path: 'changePassword', element: <ChangePassword /> },
        { path: 'otpVerification', element: <OtpVerification /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/dashboard/404" /> },
        {
          path: 'roles',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'role/permission')
              ?.length > 0 ? (
              <Rolepermission />
            ) : (
              <Navigate to="/404" />
            )
        },
        {
          path: 'user',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'users')?.length >
              0 ? (
              <User />
            ) : (
              <Navigate to="/404" />
            )
        },
        {
          path: 'properties',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'properties')
              ?.length > 0 ? (
              <Properties />
            ) : (
              <Navigate to="/404" />
            )
        },
        {
          path: 'reservations',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'reservations')
              ?.length > 0 ? (
              <Reservations />
            ) : (
              <Navigate to="/404" />
            )
        },
        {
          path: 'transactions',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'transactions')
              ?.length > 0 ? (
              <Transactions />
            ) : (
              <Navigate to="/404" />
            )
        },
        // {
        //   path: 'app',
        //   element:
        //     localPerm?.filter((item) => item.permission_name.toLowerCase() == 'dashboard')?.length >
        //     0 ? (
        //       <DashboardApp />
        //     ) : (
        //       <Navigate to="/404" />
        //     )
        // },
        {
          path: 'inquiry',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'inquiries')?.length >
              0 ? (
              <Requests />
            ) : (
              <Navigate to="/404" />
            )
        },
        {
          path: 'faq',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'faqs')?.length >
              0 ? (
              <Faqs />
            ) : (
              <Navigate to="/404" />
            )
        },
        {
          path: 'faq_categories',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'faq categories')
              ?.length > 0 ? (
              <FaqCategories />
            ) : (
              <Navigate to="/404" />
            )
        },
        {
          path: 'feedback',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'feedback')?.length >
              0 ? (
              <Feedback />
            ) : (
              <Navigate to="/404" />
            )
        },
        {
          path: 'Wallets',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'wallet')?.length >
              0 ? (
              <Wallet />
            ) : (
              <Navigate to="/404" />
            )
        },
        {
          path: 'bugs',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'bugs')?.length >
              0 ? (
              <Bugs />
            ) : (
              <Navigate to="/404" />
            )
        },
        {
          path: 'KycVerification',
          element:
            // localPerm?.filter((item) => item.permission_name.toLowerCase() == 'KycVerification')?.length >
            // 0 ? (
            <KycVerification />
          // ) : (
          //   <Navigate to="/404" />
          // )
        }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
