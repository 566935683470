import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
    Card,
    Table,
    Stack,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Modal,
    CircularProgress,
    Pagination,
    TextField,
    Grid,
    CardContent,
    Divider,
    Alert,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Avatar,
    Link,
} from '@mui/material';
import Label from 'src/components/Label';
import { LoadingButton } from '@mui/lab';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';

import pallete from '../theme/palette';
import { api } from '../Api';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import StatusDropDown from 'src/layouts/dashboard/StatusDropDown';
import { Encryption } from 'src/functions/function';

export default function KycVerification() {
    const { t } = useTranslation();

    const TABLE_HEAD = [
        { id: 'name', label: t('Users.table.0'), alignRight: false },
        { id: 'email', label: t('Users.table.1'), alignRight: false },
        { id: 'type', label: "Type", alignRight: false },
        { id: 'attachment', label: "Attachment", alignRight: false },
        { id: 'status', label: "Status", alignRight: false },
        { id: 'action', label: t('Users.table.5'), alignRight: false }
    ];

    const [permission, setpermission] = useState([]);
    const requirement = ['visible', 'change status'];

    function checkRender() {
        let check = 'kyc verification';
        const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
            (item) => item.permission_name.toLowerCase() == check
        );
        localPerm[0]?.permission_types?.filter((items) =>
            requirement.includes(items.permission_type_name.toLowerCase())
                ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
                : false
        );
    }

    useEffect(() => {
        checkRender();
    }, []);

    const [list, setlist] = useState([]);
    const [search, setsearch] = useState('');
    const [loading, setloading] = useState(true);
    const [searchLoading, setsearchLoading] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [page, setPage] = useState(1);
    const [user, setuser] = useState(null);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('');
    const [statusType, setType] = useState('');
    const [alertSuccess, setalertSuccess] = useState({ visible: false, message: '' });
    const [open, setOpen] = useState(false);
    const [nameCategory, setNameCategory] = useState('');
    const [alert, setalert] = useState({ visible: false, message: '' });
    const [status, setstatus] = useState(null);

    const handlePageChange = (event, value) => {
        list.length = 0;
        setPage(value);
        get_documents(value);
    };

    function get_documents(page) {
        setloading(true);
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);

        var formdata = new FormData();
        status &&
            status != 'All' &&
            formdata.append('status', Encryption(status, process.env.REACT_APP_SECRET_KEY));
        search.length > 0 &&
            formdata.append('search', search);
        page && formdata.append('page', page);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(api.getDocuments, requestOptions)
            .then((response) => response.text())
            .then((result_) => {
                const result = JSON.parse(result_);
                if (result.success === true) {
                    result?.count ? setpagesCount(Math.ceil(result?.count / 10)) : setpagesCount(1);
                    setlist(result.data);
                } else {
                    setlist([]);
                    setpagesCount(0);
                }
            })
            .catch((error) => console.log('error', error))
            .finally(() => {
                setloading(false);
                setsearchLoading(false);
            });
    }

    useEffect(() => {
        get_documents();
    }, []);

    const handleRequestSort = (event, property) => {
        if (property !== 'action') {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = list.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const isUserNotFound = list.length === 0;

    const refreshAdmin = () => {
        get_documents(page);
    };

    useEffect(() => {
        if (user && statusType === "approve") {
            changeStatus()
        }
    }, [user])


    function changeStatus() {
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${token}`);

        const formdata = new FormData();

        formdata.append('user_id', user?.id);
        formdata.append('status', statusType === "approve" ? 1 : 2);
        statusType === "decline" && formdata.append('reason', nameCategory);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        setloading(true);

        fetch(api.change_documents_status, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                const response = JSON.parse(result);
                if (response.success === true) {
                    refreshAdmin();
                    setalertSuccess({ visible: true, message: response.message });
                    setTimeout(() => {
                        setalertSuccess({ visible: false, message: response.message });
                    }, 2000);
                    setType('')
                }
            })
            .catch((error) => console.log('error', error))
            .finally(() => { setloading(false); handleClose() });
    }

    useEffect(() => {
        if (search.length == 0) {
            list.length = 0;
            get_documents();
        }
    }, [search]);

    const boxStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        maxWidth: '40%',
        maxHeight: '90%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        overflow: 'auto',
        p: 4,
        border: '2px solid',
        borderColor: 'primary.main'
    };

    const style = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        width: '100%',
        height: '80%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 1,
        border: '2px solid',
        borderColor: 'primary.main',
        overflow: 'hidden'
    };

    const handleOpen = () => { setOpen(true); setNameCategory('') };
    const handleClose = () => setOpen(false);

    const handleValidateEdit = () => {
        if (!nameCategory) {
            setalert({ visible: true, message: 'Decline reason is required' });
            setTimeout(() => {
                setalert({ visible: false, message: 'Decline reason is required' });
            }, 2000);
        } else {
            setType("decline")
            changeStatus()
        }
    }

    return (
        <Page title={`KycVerification | ${process.env.REACT_APP_PROJECTNAME}`}>
            <Container>

                <Modal
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%'
                    }}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{ ...boxStyle, style }} className="admin_modal_pa">

                        <Box p={2} pb={0} flexDirection={'column'} display={'flex'} flex={1}>
                            <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                Add Decline Reason
                            </Typography>

                            <CardContent>
                                <TextField
                                    multiline
                                    id="outlined-error-helper-text"
                                    fullWidth
                                    label={'Reason'}
                                    type="text"
                                    value={nameCategory}
                                    onChange={(val) => {
                                        setNameCategory(val.target.value);
                                    }}
                                    variant="outlined"
                                    display="inline"
                                    rows={10}
                                />
                            </CardContent>
                            <Divider />
                            {alert && alert.visible && <Alert severity="error">{alert.message}</Alert>}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    p: 2
                                }}
                            >
                                <LoadingButton
                                    disabled={false}
                                    loading={loading}
                                    sx={{
                                        marginRight: 1
                                    }}
                                    onClick={() => {
                                        handleValidateEdit();
                                    }}
                                    color="primary"
                                    variant="contained"
                                >
                                    Save
                                </LoadingButton>
                                <LoadingButton
                                    sx={{
                                        marginRight: 1
                                    }}
                                    onClick={handleClose}
                                    variant="outlined"
                                >
                                    Cancel
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>
                </Modal>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <Typography variant="h4" gutterBottom>
                        KYC Verification
                    </Typography>
                </Stack>

                {alertSuccess && alertSuccess.visible && <Alert style={{ marginBottom: 20 }} severity="success">{alertSuccess.message}</Alert>}

                <Card>
                    <Box
                        p={2}
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            flex: 1
                        }}
                        className="top_column"
                    >
                        <Searchbar
                            width={'100%'}
                            value={search}
                            setFunction={(val) => {
                                setsearch(val);
                            }}
                            className="width_full_property"
                            placeholder={t('Agents.search')}
                            onEnter={() => {
                                setsearchLoading(true);
                                setPage(1);
                                get_documents(1);
                            }}
                        />

                        <StatusDropDown
                            placeholder={"Status"}
                            type={'kycVerification'}
                            width={'25%'}
                            setFunction={(val) => {
                                setstatus(val);
                            }}
                        />

                        <LoadingButton
                            style={{ width: '15%', marginLeft: '2.5%' }}
                            variant="contained"
                            loading={searchLoading}
                            onClick={() => {
                                setsearchLoading(true);
                                setPage(1);
                                get_documents(1);
                            }}
                            className="width_full_status"
                        >
                            {' '}
                            <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
                        </LoadingButton>
                    </Box>

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={list.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {list.map((row) => {
                                        const { id, seen, status, type, attachment } = row;
                                        const userData = row?.user

                                        return (
                                            <TableRow
                                                hover
                                                onClick={() => { }}
                                                style={{
                                                    backgroundColor: seen == '0' && 'rgba(253,208,151,0.2)',
                                                    cursor: seen == '0' ? 'pointer' : 'default'
                                                }}
                                                key={id}
                                                tabIndex={-1}
                                                role="checkbox"
                                            >
                                                <TableCell scope="row" padding="normal" style={{ width: 150 }}>
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={1}
                                                        style={{ width: 150 }}
                                                    >
                                                        <Avatar alt={userData?.name} src={userData?.avatar + '?v=' + Date.now()} />
                                                        <Box style={{ display: 'flex', flexDirection: 'column', width: 150 }}>
                                                            <Typography variant="subtitle2" flexWrap={'wrap'}>
                                                                {userData?.name}
                                                            </Typography>
                                                        </Box>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="left" style={{ maxWidth: 150 }}>
                                                    <Typography noWrap>{userData?.email}</Typography>
                                                </TableCell>
                                                <TableCell align="left" style={{ maxWidth: 100 }} >
                                                    <Typography noWrap style={{ textTransform: "capitalize" }}>
                                                        {type ? type : 'N/A'}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left" style={{ maxWidth: 150, cursor: 'pointer' }} component="a">
                                                    <Typography noWrap>
                                                        <Link target="_blank" href={attachment} variant="subtitle1">
                                                            {attachment}
                                                        </Link>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left" style={{ maxWidth: 100 }}>
                                                    <Label
                                                        style={{ height: 30, paddingLeft: 30, paddingRight: 30, width: 100 }}
                                                        variant="ghost"
                                                        color={
                                                            (userData?.is_verified === "1" && 'success') ||
                                                            (userData?.is_verified === "3" && 'info') ||
                                                            (userData?.is_verified === "3" && 'error') ||
                                                            'error'
                                                        }
                                                    >
                                                        <Typography
                                                            style={{
                                                                textTransform: 'capitalize',
                                                                fontWeight: 'bold',
                                                                fontSize: 12
                                                            }}
                                                        >
                                                            {
                                                                (userData?.is_verified === "1" && "Approved") ||
                                                                (userData?.is_verified === "2" && "Declined") ||
                                                                (userData?.is_verified === "3" && "Pending")
                                                            }
                                                        </Typography>
                                                    </Label>
                                                </TableCell>

                                                <TableCell align="left" style={{ maxWidth: 100 }}>
                                                    {permission.includes('change status') && (
                                                        <>
                                                            <Button
                                                                disabled={userData?.is_verified === "1" || userData?.is_verified === "2" ? true : false}
                                                                size="small"
                                                                onClick={() => { setuser(userData); setType("approve") }}
                                                                style={{
                                                                    justifyContent: 'flex-start',
                                                                    marginTop: 5,
                                                                    width: 100,
                                                                    alignSelf: 'center',
                                                                    marginRight: 10,
                                                                    fontSize: 11
                                                                }}
                                                                variant="outlined"
                                                                component={RouterLink}
                                                                to="#"
                                                            >
                                                                {userData?.is_verified === "1" ? "Approved" : "Approve"}
                                                            </Button>

                                                            <Button
                                                                disabled={userData?.is_verified === "2" ? true : false}
                                                                size="small"
                                                                onClick={() => { setuser(userData); handleOpen() }}
                                                                style={{
                                                                    justifyContent: 'flex-start',
                                                                    marginTop: 5,
                                                                    width: 100,
                                                                    alignSelf: 'center',
                                                                    marginRight: 10,
                                                                    fontSize: 11
                                                                }}
                                                                variant="outlined"
                                                                component={RouterLink}
                                                                to="#"
                                                            >
                                                                {userData?.is_verified === "2" ? "Declined" : "Decline"}
                                                            </Button>
                                                        </>
                                                    )}

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>

                                {isUserNotFound && !loading && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={search} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                            {loading && (
                                <Box
                                    py={5}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flex: 1
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </TableContainer>
                    </Scrollbar>
                </Card>

                {
                    !loading && !searchLoading && pagesCount > 1 && (
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                marginTop: 10,
                                alignItems: 'center'
                            }}
                        >
                            <Pagination
                                count={pagesCount}
                                color="primary"
                                variant="outlined"
                                shape="rounded"
                                onChange={handlePageChange}
                                page={page}
                            />
                        </Box>
                    )
                }
            </Container >
        </Page >
    );
}
