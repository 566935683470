import { useState } from 'react';
import { InputAdornment, OutlinedInput, IconButton } from '@mui/material';
import Iconify from '../../components/Iconify';

export default function Searchbar({ setFunction, value, width, placeholder, onEnter }) {
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onEnter && onEnter();
    }
  };

  const handleClear = () => {
    setFunction('');
  };

  return (
    <OutlinedInput
      className="width_full"
      style={{ width: width ? width : '40%' }}
      variant="contained"
      contained
      value={value}
      disableUnderline
      placeholder={placeholder ? placeholder : 'Search…'}
      startAdornment={
        <InputAdornment position="start">
          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
        </InputAdornment>
      }
      endAdornment={
        value.length > 0 && (
          <InputAdornment position="end">
            <IconButton onClick={handleClear}>
              <Iconify
                icon="eva:close-fill"
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </IconButton>
          </InputAdornment>
        )
      }
      onKeyPress={handleKeyPress}
      onChange={(val) => setFunction(val.target.value)}
    />
  );
}
