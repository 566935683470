// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography, Stack } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { ForgotForm } from 'src/sections/authentication/forgot';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 4),
  padding: theme.spacing(5),
  backgroundColor: theme.palette.drawerBackground
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Forgot() {

  return (
    <RootStyle title={`Forgot | ${process.env.REACT_APP_PROJECTNAME}`}>
      {/* <AuthLayout nologo>
       
      </AuthLayout> */}

      <SectionStyle sx={{ display: { xs: 'none', md: 'flex' } }}>
        <img src={process.env.REACT_APP_LOGO} alt="login" />
      </SectionStyle>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 2 }}>
            <Typography variant="h5" gutterBottom>
              Enter your registered email address below
            </Typography>
          </Stack>

          <ForgotForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
