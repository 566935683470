import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

import { api } from 'src/Api';
import PropertyStatusDropdown from 'src/layouts/dashboard/PropertyStatusDropdown';

export default function ChangePropertyModal({ handleClose, property, refreshAdmin }) {
  function AssignStatus(roleSelected, userId) {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('listing_id', userId);
    formdata.append('status', roleSelected);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.update_listing_status, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          refreshAdmin();
        }
      })
      .catch((error) => console.error(error));
  }
  return (
    <Box p={3} flexDirection={'column'} display={'flex'} flex={1}>
      <Typography py={3} align="center" variant="h5">
        Change Property Status
      </Typography>
      <PropertyStatusDropdown
        alreadyRole={property?.status}
        fullWidth={true}
        setRole={(roleSelected) => {
          AssignStatus(roleSelected, property?.id);
        }}
      />
      <Button style={{ marginTop: 10 }} color="primary" variant="outlined" onClick={handleClose}>
        Close
      </Button>
    </Box>
  );
}
