import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';

// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import { api } from '../../../Api';
// component
import Iconify from '../../../components/Iconify';
import { Decryption } from 'src/functions/function';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [alert, setalert] = useState({ visible: false, message: '' });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: false
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      LoginFunction();
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps, setFieldValue } = formik;
  console.log("🚀 ~ LoginForm ~ values:", values)

  // Load credentials from local storage when the component mounts.
  const savedEmail = localStorage.getItem('email');
  console.log("🚀 ~ useEffect ~ savedEmail:", savedEmail)
  const savedPassword = localStorage.getItem('password');
  console.log("🚀 ~ useEffect ~ savedPassword:", savedPassword)
  const rememberMe = localStorage.getItem('remember') === 'true';
  console.log("🚀 ~ useEffect ~ rememberMe:", rememberMe)
  useEffect(() => {

    if (savedEmail && savedPassword) {
      setFieldValue('email', savedEmail);
      setFieldValue('password', savedPassword);
      setFieldValue('remember', rememberMe);
    }
  }, [setFieldValue]);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const LoginFunction = () => {
    setisSubmitting(true);

    const formData = new FormData();
    formData.append('email', values.email);
    formData.append('password', values.password);

    const requestOptions = {
      method: 'POST',
      body: formData,
      redirect: 'follow'
    };

    fetch(api.login, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          localStorage.setItem('token', result.data.token);
          localStorage.setItem('user', JSON.stringify(result.user));

          if (values.remember) {
            // Save credentials to local storage
            localStorage.setItem('email', values.email);
            localStorage.setItem('password', values.password);
            localStorage.setItem('remember', values.remember);
          } else {
            // Remove credentials from local storage
            localStorage.removeItem('email');
            localStorage.removeItem('password');
            localStorage.removeItem('remember');
          }

          getSideBar(result.data.token, result.path);
        } else {
          setalert({ visible: true, message: result.message });
        }
      })
      .catch((error) => {
        console.log('error', error);
        setisSubmitting(false);
      })
      .finally(() => {
        setisSubmitting(false);
      });
  };

  function getSideBar(token, path) {
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(api.sideBar, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          let decryptedData = Decryption(result.data);
          console.log(decryptedData, 'YE CHECK KRW');
          localStorage.setItem('permissions', JSON.stringify(decryptedData));
          setTimeout(() => {
            navigate(path, { replace: true });
          }, 1000);
        } else {
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setisSubmitting(false);
      });
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />
          <Link component={RouterLink} variant="subtitle2" to="/forgot" underline="hover">
            Forgot password?
          </Link>
        </Stack>
        {alert.visible && (
          <Alert
            onClose={() => {
              setalert({ visible: false, message: '' });
            }}
            severity="error"
            style={{ fontSize: 12, marginBottom: 20 }}
          >
            {alert.message}
          </Alert>
        )}
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
