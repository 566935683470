import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Link as RouterLink } from 'react-router-dom';

import {
    Card,
    Table,
    Stack,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Modal,
    CircularProgress,
    Pagination,
    TextField,
    Grid,
    CardContent,
    Divider,
    Alert,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Avatar
} from '@mui/material';
import Label from 'src/components/Label';
import { LoadingButton } from '@mui/lab';

// components
import Page from '../components/Page';
import StatusDropDown from 'src/layouts/dashboard/StatusDropDown';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';

import pallete from '../theme/palette';
import { api } from '../Api';
import { useTranslation } from 'react-i18next';

export default function FaqCategories() {
    const { t } = useTranslation();

    const TABLE_HEAD = [
        { id: 'categories', label: "Category", alignRight: false },
        { id: 'status', label: "Status", alignRight: false },
        { id: 'action', label: "Action", alignRight: false }
    ];
    const [permission, setpermission] = useState([]);
    const requirement = ['visible', 'add', 'edit', 'delete'];
    function checkRender() {
        let check = 'faq categories';
        const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
            (item) => item.permission_name.toLowerCase() == check
        );
        localPerm[0]?.permission_types?.filter((items) =>
            requirement.includes(items.permission_type_name.toLowerCase())
                ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
                : false
        );
    }

    useEffect(() => {
        checkRender();
    }, []);

    const [list, setlist] = useState([]);
    const [search, setsearch] = useState('');
    const [loading, setloading] = useState(true);
    const [searchLoading, setsearchLoading] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [page, setPage] = React.useState(1);
    const [nameCategory, setNameCategory] = useState('');
    const [alert, setalert] = useState({ visible: false, message: '' });
    const [open, setOpen] = React.useState(false);
    const [openUser, setOpenUser] = React.useState(false);
    const [openMesg, setOpenMesg] = useState(false);
    const [user, setuser] = useState(null);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('');
    const [alertSuccess, setalertSuccess] = useState({ visible: false, message: '' });

    const handlePageChange = (event, value) => {
        list.length = 0;
        setPage(value);
        getFaqCategories(value);
    };

    useEffect(() => {
        getFaqCategories();
    }, []);

    function getFaqCategories(page) {
        setloading(true);
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);

        var formdata = new FormData();
        search.length > 0 && formdata.append('search', search);
        page && formdata.append('page', page);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(api.getFaqCategory, requestOptions)
            .then((response) => response.text())
            .then((result_) => {
                const result = JSON.parse(result_);
                if (result.success === true) {
                    list.length = 0;
                    let decryptedData = result.data;
                    setlist(decryptedData);
                    result?.count ? setpagesCount(Math.ceil(result?.count / 10)) : setpagesCount(1);
                } else {
                    setlist([]);
                    setpagesCount(0);
                }
            })
            .catch((error) => console.log('error', error))
            .finally(() => {
                setloading(false);
                setsearchLoading(false);
            });
    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    function openModal(user) {
        setuser(user);
        handleOpen();
    }

    function openUserModal(user) {
        setuser(user);
        setOpenUser(true);
    }

    const handleCloseUserModal = () => setOpenUser(false);
    const handleCloseMesgModal = () => { setOpenMesg(false); };
    const descriptionElementRef = React.useRef(null);
    function openMesgModal(user) {
        setuser(user);
        setOpenMesg(true);
    }

    const handleRequestSort = (event, property) => {
        if (property !== 'action') {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = list.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const isUserNotFound = list.length === 0;

    const style = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        width: '100%',
        height: '80%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 1,
        border: '2px solid',
        borderColor: 'primary.main',
        overflow: 'hidden'
    };

    const boxStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        maxWidth: '40%',
        maxHeight: '90%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        overflow: 'auto',
        p: 4,
        border: '2px solid',
        borderColor: 'primary.main'
    };

    const refreshAdmin = () => {
        setOpen(false);
        setOpenUser(false);
        getFaqCategories(page);
    };

    function addCategory(type) {
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${token}`);

        const formdata = new FormData();

        formdata.append('name', nameCategory);
        type === "edit" && formdata.append('id', user?.id);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        setloading(true);

        fetch(api.saveFaqCategory, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                const response = JSON.parse(result);
                if (response.success === true) {
                    refreshAdmin();
                    setNameCategory('')
                    setalertSuccess({ visible: true, message: response.message });
                    setTimeout(() => {
                        setalertSuccess({ visible: false, message: response.message });
                    }, 2000);
                }
            })
            .catch((error) => console.log('error', error))
            .finally(() => setloading(false));
    }

    function deleteCategory(row) {
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${token}`);

        const formdata = new FormData();

        formdata.append('id', row?.id);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        setloading(true);

        fetch(api.deleteFaqCategory, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                const response = JSON.parse(result);
                if (response.success === true) {
                    refreshAdmin();
                    setalertSuccess({ visible: true, message: response.message });
                    setTimeout(() => {
                        setalertSuccess({ visible: false, message: response.message });
                    }, 2000);
                }
            })
            .catch((error) => console.log('error', error))
            .finally(() => setloading(false));
    }

    useEffect(() => {
        if (search.length == 0) {
            list.length = 0;
            getFaqCategories();
        }
    }, [search]);

    useEffect(() => {
        if (user) {
            setNameCategory(user?.question)
        }
    }, [user]);

    const handleValidateAdd = () => {
        if (!nameCategory) {
            setalert({ visible: true, message: 'Category name is required' });
            setTimeout(() => {
                setalert({ visible: false, message: 'Category name is required' });
            }, 2000);
        } else {
            addCategory()
        }
    }
    const handleValidateEdit = () => {
        if (!nameCategory) {
            setalert({ visible: true, message: 'Category name is required' });
            setTimeout(() => {
                setalert({ visible: false, message: 'Category name is required' });
            }, 2000);
        } else {
            addCategory('edit')
        }
    }

    return (
        <Page title={`Users | ${process.env.REACT_APP_PROJECTNAME}`}>
            <Container>
                {/* Edit CATEGORY  */}
                <Modal
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%'
                    }}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{ ...boxStyle, style }} className="admin_modal_pa">

                        <Box p={3} flexDirection={'column'} display={'flex'} flex={1}>
                            <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                Edit Category
                            </Typography>

                            <CardContent>
                                <TextField
                                    multiline
                                    id="outlined-error-helper-text"
                                    fullWidth
                                    label={'Edit'}
                                    type="text"
                                    value={nameCategory}
                                    onChange={(val) => {
                                        setNameCategory(val.target.value);
                                    }}
                                    variant="outlined"
                                    display="inline"
                                />
                            </CardContent>
                            <Divider />
                            {alert && alert.visible && <Alert severity="error">{alert.message}</Alert>}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    p: 2
                                }}
                            >
                                <LoadingButton
                                    disabled={false}
                                    loading={loading}
                                    sx={{
                                        marginRight: 1
                                    }}
                                    onClick={() => {
                                        handleValidateEdit();
                                    }}
                                    color="primary"
                                    variant="contained"
                                >
                                    {t('EditProfile.buttons.1')}
                                </LoadingButton>
                                <LoadingButton
                                    sx={{
                                        marginRight: 1
                                    }}
                                    onClick={handleClose}
                                    variant="outlined"
                                >
                                    {t('EditProfile.buttons.2')}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>
                </Modal>

                {/* Add CATEGORY */}
                <Modal
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%'
                    }}
                    open={openUser}
                    onClose={handleCloseUserModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{ ...boxStyle, style }} className="admin_modal_pa">
                        <Box p={3} flexDirection={'column'} display={'flex'} flex={1}>
                            <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                Add Category
                            </Typography>

                            <CardContent>
                                <TextField
                                    multiline
                                    id="outlined-error-helper-text"
                                    fullWidth
                                    label={'Add'}
                                    type="text"
                                    value={nameCategory}
                                    onChange={(val) => {
                                        setNameCategory(val.target.value);
                                    }}
                                    variant="outlined"
                                    display="inline"
                                />
                            </CardContent>
                            <Divider />
                            {alert && alert.visible && <Alert severity="error">{alert.message}</Alert>}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    p: 2
                                }}
                            >
                                <LoadingButton
                                    disabled={false}
                                    loading={loading}
                                    sx={{
                                        marginRight: 1
                                    }}
                                    onClick={() => {
                                        handleValidateAdd()
                                    }}
                                    color="primary"
                                    variant="contained"
                                >
                                    {t('EditProfile.buttons.1')}
                                </LoadingButton>
                                <LoadingButton
                                    sx={{
                                        marginRight: 1
                                    }}
                                    onClick={handleCloseUserModal}
                                    variant="outlined"
                                >
                                    {t('EditProfile.buttons.2')}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>
                </Modal>

                {/* Question */}
                <Dialog
                    open={openMesg}
                    onClose={handleCloseMesgModal}
                    scroll={"paper"}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <Box sx={{ minWidth: 600, maxHeight: 500 }}>
                        <DialogTitle id="scroll-dialog-title">Question</DialogTitle>
                        <DialogContent dividers={'paper'}>
                            <DialogContentText
                                id="scroll-dialog-description"
                                ref={descriptionElementRef}
                                tabIndex={-1}
                            >
                                {user?.question}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseMesgModal}>Cancel</Button>
                        </DialogActions>
                    </Box>
                </Dialog>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <Typography variant="h4" gutterBottom>
                        FAQ Categories
                    </Typography>
                    {permission.includes('add') && (
                        <Button
                            onClick={() => {
                                openUserModal(true);
                                setNameCategory('')
                            }}
                            variant="contained"
                            component={RouterLink}
                            to="#"
                            startIcon={<Iconify icon="eva:plus-fill" />}
                        >
                            ADD
                        </Button>
                    )}
                </Stack>

                {alertSuccess && alertSuccess.visible && <Alert style={{ marginBottom: 20 }} severity="success">{alertSuccess.message}</Alert>}

                <Card>
                    <Box
                        p={2}
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            flex: 1
                        }}
                        className="top_column"
                    >
                        <Searchbar
                            width={'100%'}
                            value={search}
                            setFunction={(val) => {
                                setsearch(val);
                            }}
                            className="width_full_property"
                            placeholder={t('Agents.search')}
                            onEnter={() => {
                                setsearchLoading(true);
                                setPage(1);
                                getFaqCategories(1);
                            }}
                        />

                        <LoadingButton
                            style={{ width: '15%', marginLeft: '2.5%' }}
                            variant="contained"
                            loading={searchLoading}
                            onClick={() => {
                                setsearchLoading(true);
                                setPage(1);
                                getFaqCategories(1);
                            }}
                            className="width_full_status"
                        >
                            {' '}
                            <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
                        </LoadingButton>
                    </Box>

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={list.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {list.map((row) => {
                                        const { id, question, seen, status } = row;

                                        return (
                                            <TableRow
                                                hover
                                                onClick={() => { }}
                                                style={{
                                                    backgroundColor: seen == '0' && 'rgba(253,208,151,0.2)',
                                                    cursor: seen == '0' ? 'pointer' : 'default'
                                                }}
                                                key={id}
                                                tabIndex={-1}
                                                role="checkbox"
                                            >
                                                <TableCell onClick={() => openMesgModal(row)} scope="row" padding="normal" style={{ maxWidth: 400, cursor: 'pointer' }}>
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={1}
                                                        style={{ width: 400 }}
                                                    >
                                                        <Box style={{ display: 'flex', flexDirection: 'column', width: 400 }}>
                                                            <Typography noWrap>
                                                                {question}
                                                            </Typography>
                                                        </Box>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell align="left" style={{ maxWidth: 200 }}>
                                                    <Label
                                                        style={{ height: 30, paddingLeft: 30, paddingRight: 30, width: 100 }}
                                                        variant="ghost"
                                                        color={
                                                            (status.toLowerCase() === 'active' && 'success') ||
                                                            (status.toLowerCase() === 'pending' && 'info') ||
                                                            (status.toLowerCase() === 'deleted' && 'error') ||
                                                            'error'
                                                        }
                                                    >
                                                        <Typography
                                                            style={{
                                                                textTransform: 'capitalize',
                                                                fontWeight: 'bold',
                                                                fontSize: 12
                                                            }}
                                                        >
                                                            {status}
                                                        </Typography>
                                                    </Label>
                                                </TableCell>

                                                <TableCell align="right" style={{ maxWidth: 150 }}>
                                                    {permission.includes('edit') && (
                                                        <>
                                                            {status.toLowerCase() !== 'deleted' &&
                                                                <Button
                                                                    size="small"
                                                                    onClick={() => { openModal(row); setalert({ visible: false, message: 'Something went wrong' }) }}
                                                                    style={{
                                                                        justifyContent: 'flex-start',
                                                                        marginTop: 5,
                                                                        width: 80,
                                                                        alignSelf: 'center',
                                                                        marginRight: 10
                                                                    }}
                                                                    variant="outlined"
                                                                    component={RouterLink}
                                                                    to="#"
                                                                    startIcon={
                                                                        <Iconify
                                                                            color={pallete.primary.main}
                                                                            icon="bx:edit"
                                                                        />
                                                                    }
                                                                >
                                                                    Edit
                                                                </Button>
                                                            }
                                                        </>
                                                    )}
                                                    {permission.includes('delete') && (
                                                        <>
                                                            {status.toLowerCase() !== 'deleted' &&
                                                                <Button
                                                                    size="small"
                                                                    onClick={() => { deleteCategory(row); setalert({ visible: true, message: 'Something went wrong' }) }}
                                                                    style={{ justifyContent: 'flex-start', marginTop: 5, width: 80, marginRight: 10 }}
                                                                    variant="outlined"
                                                                    component={RouterLink}
                                                                    to="#"

                                                                    startIcon={
                                                                        <Iconify color={pallete.primary.main} icon="mdi:delete" />
                                                                    }
                                                                >
                                                                    Delete
                                                                </Button>
                                                            }
                                                        </>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>

                                {isUserNotFound && !loading && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={search} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                            {loading && (
                                <Box
                                    py={5}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flex: 1
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </TableContainer>
                    </Scrollbar>
                </Card>
                {!loading && !searchLoading && pagesCount > 1 && (
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            marginTop: 10,
                            alignItems: 'center'
                        }}
                    >
                        <Pagination
                            count={pagesCount}
                            color="primary"
                            variant="outlined"
                            shape="rounded"
                            onChange={handlePageChange}
                            page={page}
                        />
                    </Box>
                )}
            </Container>
        </Page>
    );
}
