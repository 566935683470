import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { mainurl } from 'src/Api';

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = 'AIzaSyCfkkFnJ7ixENdtACx3-Q5Mewh3wftkCo8';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function GooglePlaces({
  width,
  onResult,
  error,
  address,
  regions,
  required,
  placeholder
}) {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  useEffect(() => {
    address && setValue(address);
  }, []);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const fetchs = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      try {
        autocompleteService.current = new window.google.maps.places.AutocompleteService();
      } catch (e) {
        console.log(e);
      }
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetchs(
      {
        input: inputValue,
        types: [regions ? '(regions)' : 'address'],
        componentRestrictions: { country: 'ZA' }
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetchs]);

  const handlePlaceSelect = (place, value) => {
    if (place) {
      const { address_components, geometry } = place;
      const streetNumber = address_components.find((component) =>
        component.types.includes('street_number')
      )?.short_name;
      const streetName = address_components.find((component) =>
        component.types.includes('route')
      )?.short_name;
      const suburb = address_components.find((component) =>
        component.types.includes('locality')
      )?.short_name;
      const state = address_components.find((component) =>
        component.types.includes('administrative_area_level_1')
      )?.short_name;
      const postCode = address_components.find((component) =>
        component.types.includes('postal_code')
      )?.short_name;
      const citys = address_components.find((addressComponent) =>
        addressComponent.types.includes('administrative_area_level_2')
      )?.short_name;
      const countrys = address_components.find((addressComponent) =>
        addressComponent.types.includes('country')
      )?.long_name;

      const lat = geometry?.location.lat;
      const lon = geometry?.location.lng;

      onResult({
        address: value?.description,
        streetnumber: streetNumber,
        streetname: streetName,
        suburb: suburb,
        state: state,
        postcode: postCode,
        city: citys,
        country: countrys,
        lat: lat,
        lon: lon
      });
    }
  };

  function getAddressComponent(place_id, value) {
    var formdata = new FormData();
    formdata.append('place_id', place_id);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch(mainurl + 'get_places', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result?.result?.address_components);
        handlePlaceSelect(result?.result, value);
      })
      .catch((error) => console.log('error', error));
  }

  return (
    <Autocomplete
      className="width_full"
      id="google-map-demo"
      sx={{ width: width || 250 }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(event, newValue) => {
        console.log(newValue);
        getAddressComponent(newValue?.place_id, newValue);
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        if (newInputValue == '') {
          // onResult({
          //   address: '',
          //   streetnumber: null,
          //   streetname: null,
          //   suburb: null,
          //   state: null,
          //   postcode: null,
          //   city: null,
          //   country: null,
          //   lat: null,
          //   lon: null
          // });
          onResult(null);
        }
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={placeholder || 'Search Location'}
          fullWidth
          error={error}
          color={error ? 'error' : 'primary'}
          required={required ? true : false}
        />
      )}
      renderOption={(props, option) => {
        const matches = option?.structured_formatting?.main_text_matched_substrings || [];
        let parts = [];
        if (matches.length > 0) {
          parts = parse(
            option?.structured_formatting.main_text,
            matches?.map((match) => [match.offset, match.offset + match.length])
          );
        }

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part?.highlight ? 'bold' : 'regular' }}
                  >
                    {part?.text}
                  </Box>
                ))}

                <Typography variant="body2" color="text.secondary">
                  {option?.structured_formatting?.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
