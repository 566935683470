import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  CircularProgress,
  Pagination,
  TextField,
  CardContent,
  Alert,
  MenuItem,
  Snackbar
} from '@mui/material';
import Label from 'src/components/Label';
import { LoadingButton } from '@mui/lab';

// components
import Page from '../components/Page';
import StatusDropDown from 'src/layouts/dashboard/StatusDropDown';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { ChangeRoleModal, ResetUserPassword, UserListHead } from '../sections/@dashboard/user';

import pallete from '../theme/palette';
import { api } from '../Api';
import { Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';
import EditUserModal from 'src/sections/@dashboard/user/EditUserModal';

export default function User() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { id: 'name', label: t('Users.table.0'), alignRight: false },
    { id: 'email', label: t('Users.table.1'), alignRight: false },
    { id: 'contact', label: t('Users.table.2'), alignRight: false },
    { id: 'status', label: t('Users.table.4'), alignRight: false },
    { id: 'role', label: 'Role', alignRight: false },
    { id: 'action', label: t('Users.table.5'), alignRight: false }
  ];
  const [permission, setpermission] = useState([]);
  const requirement = ['visible', 'change password', 'edit role', 'edit user', "change status"];

  function checkRender() {
    let check = 'users';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  useEffect(() => {
    checkRender();
  }, []);

  const [list, setlist] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [search, setsearch] = useState('');
  const [status, setstatus] = useState(null);
  const [loading, setloading] = useState(true);
  const [searchLoading, setsearchLoading] = useState(false);
  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);
  const [statusss, setStatus] = useState(null);
  const [alert, setalert] = useState({ visible: false, message: '' });
  const [selectedStatus, setSelectedStatus] = useState('');
  const [alertSuccess, setalertSuccess] = useState({ visible: false, message: '' });

  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    getUsers(value);
  };

  function getUsers(page) {
    setloading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    search.length > 0 &&
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_KEY));
    status &&
      status != 'All' &&
      formdata.append('status', Encryption(status, process.env.REACT_APP_SECRET_KEY));
    page && formdata.append('page', Encryption(page, process.env.REACT_APP_SECRET_KEY));
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.getUsers, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          list.length = 0;
          let decryptedData = result.data;
          setlist(decryptedData);
          result?.count ? setpagesCount(Math.ceil(result?.count / 10)) : setpagesCount(1);
        } else {
          setlist([]);
          setpagesCount(0);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
        setsearchLoading(false);
      });
  }

  function getUserStatus() {
    setloading(true);
    const myHeaders = new Headers();

    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    status &&
      formdata.append('status', "user");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.status, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          setStatusList(result.data);
        } else {
          setStatusList([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
      });
  }

  const handleOpenStatus = () => setOpenStatus(true);
  const handleCloseStatus = () => { setOpenStatus(false); setStatus(null) }
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getUsers();
    getUserStatus()
  }, []);

  const [open, setOpen] = React.useState(false);
  const [openStatus, setOpenStatus] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);
  const [openReset, setOpenReset] = React.useState(false);
  const [user, setuser] = useState(null);

  useEffect(() => {
    if (user) {
      const selected = statusList?.filter((e) => e.name.toLowerCase() === user?.status.toLowerCase())
      setSelectedStatus(selected[0])
    }
  }, [user]);


  function openModal(user) {
    setuser(user);
    handleOpen();
  }

  function openModalStatus(user) {
    setuser(user);
    handleOpenStatus();
  }

  function openResetModal(user) {
    setuser(user);
    setOpenReset(true);
  }

  function openUserModal(user) {
    setuser(user);
    setOpenUser(true);
  }

  const handleCloseUserModal = () => setOpenUser(false);
  const handleCloseModal = () => setOpenReset(false);

  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (event, property) => {
    if (property !== 'action') {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isUserNotFound = list.length === 0;
  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
    height: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
    border: '2px solid',
    borderColor: 'primary.main',
    overflow: 'hidden'
  };

  const boxStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    maxWidth: '40%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    p: 4,
    border: '2px solid',
    borderColor: 'primary.main'
  };

  const refreshAdmin = () => {
    setOpen(false);
    setOpenUser(false);
    getUsers(page);
  };

  useEffect(() => {
    if (search.length == 0) {
      list.length = 0;
      getUsers();
    }
  }, [search]);

  useEffect(() => {
    list.length = 0;
    getUsers();
  }, [status]);

  const handleChange = (event) => {
    setStatus(event.target.value);
    handleChangeStatus(event.target.value)
  };

  function handleChangeStatus(status) {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();

    formdata.append('user_id', user.id);
    formdata.append('status', status.name);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    setloading(true);
    fetch(api.update_user_status, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const response = JSON.parse(result);
        if (response.success === true) {
          refreshAdmin();
          handleCloseStatus()
          setalertSuccess({ visible: true, message: response.message });
          setTimeout(() => {
            setalertSuccess({ visible: false, message: response.message });
          }, 2000);
        } else {
          setalert({ visible: true, message: 'Something went wrong' });
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => { setloading(false); handleCloseStatus() });
  }

  return (
    <Page title={`Users | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        {/* RESET USER MODAL */}
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
          open={openReset}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle, style }} className="admin_modal_pa">
            <ResetUserPassword user={user} handleClose={handleCloseModal} />
          </Box>
        </Modal>

        {/* CHANGE ROLE  */}
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle, style }} className="admin_modal_pa">
            <ChangeRoleModal user={user} handleClose={handleClose} refreshAdmin={refreshAdmin} />
          </Box>
        </Modal>

        {/* EDIT USER */}
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
          open={openUser}
          onClose={handleCloseUserModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              maxWidth: '60%',
              maxHeight: '90%',
              bgcolor: 'background.paper',
              boxShadow: 24,
              overflow: 'auto',
              p: 1,
              border: '2px solid',
              borderColor: 'primary.main',
              flex: 1,
              style
            }}
            className="admin_modal_pa"
          >
            <EditUserModal
              user={user}
              handleClose={handleCloseUserModal}
              refreshAdmin={refreshAdmin}
            />
          </Box>
        </Modal>

        {/* CHANGE STATUS */}
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
          open={openStatus}
          onClose={handleCloseStatus}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle, style }} className="admin_modal_pa">

            <Box p={3} flexDirection={'column'} display={'flex'} flex={1}>
              <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Change Status
              </Typography>
              <CardContent>

                <TextField
                  style={{
                    width: '100%',
                  }}
                  select
                  value={selectedStatus ? selectedStatus : statusss}
                  label={"Status"}
                  onChange={handleChange}
                  className="width_full_status"
                >
                  {statusList.map((option) => (
                    <MenuItem key={option.id} value={option}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </CardContent>
              {alert && alert.visible && <Alert severity="error">{alert.message}</Alert>}
              <Button style={{ marginTop: 10 }} color="primary" variant="outlined" onClick={handleCloseStatus}>
                Close
              </Button>
            </Box>
          </Box>
        </Modal>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
        </Stack>

        {alertSuccess && alertSuccess.visible && <Alert style={{ marginBottom: 20 }} severity="success">{alertSuccess.message}</Alert>}
        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
            className="top_column"
          >
            <Searchbar
              width={'50%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              className="width_full_property"
              placeholder={t('Agents.search')}
              onEnter={() => {
                setsearchLoading(true);
                list.length = 0;
                getUsers();
              }}
            />
            <StatusDropDown
              placeholder={t('Users.status')}
              type={'user'}
              width={'25%'}
              setFunction={(val) => {
                setstatus(val);
              }}
            />

            <LoadingButton
              style={{ width: '15%', marginLeft: '2.5%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                setPage(1);
                getUsers(1);
              }}
              className="width_full_status"
            >
              {' '}
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={list.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {list.map((row) => {
                    const { id, name, contact_no, avatar, email, seen, status, role } = row;
                    console.log("🚀 ~ {list.map ~ name:", typeof name)

                    return (
                      <TableRow
                        hover
                        onClick={() => { }}
                        style={{
                          backgroundColor: seen == '0' && 'rgba(253,208,151,0.2)',
                          cursor: seen == '0' ? 'pointer' : 'default'
                        }}
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell scope="row" padding="normal" style={{ width: 150 }}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            style={{ width: 150 }}
                          >
                            <Avatar alt={name} src={avatar + '?v=' + Date.now()} />
                            <Box style={{ display: 'flex', flexDirection: 'column', width: 120 }}>
                              <Typography variant="subtitle2" flexWrap={'wrap'}>
                                {name === "undefined" ? "N/A" : name}
                              </Typography>
                            </Box>
                          </Stack>
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 150 }}>
                          <Typography noWrap>{email === "undefined" ? "N/A" : email}</Typography>
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 80 }}>
                          {contact_no ? contact_no : 'N/A'}
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 100 }}>
                          <Label
                            style={{ height: 30, width: 100 }}
                            variant="ghost"
                            color={
                              (status.toLowerCase() === 'active' && 'success') ||
                              (status.toLowerCase() === 'pending' && 'info') ||
                              (status.toLowerCase() === 'deleted' && 'error') ||
                              'error'
                            }
                          >
                            <Typography
                              style={{
                                textTransform: 'capitalize',
                                fontWeight: 'bold',
                                fontSize: 12
                              }}
                            >
                              {status}
                            </Typography>
                          </Label>
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 80 }}>
                          {role ? role?.role_name : 'N/A'}
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 130 }}>
                          {permission.includes('edit user') && (
                            <Button
                              size="small"
                              onClick={() => openUserModal(row)}
                              style={{
                                justifyContent: 'flex-start',
                                marginTop: 5,
                                width: 165,
                                alignSelf: 'center'
                              }}
                              variant="outlined"
                              component={RouterLink}
                              to="#"
                              startIcon={
                                <Iconify color={pallete.primary.main} icon="solar:user-id-bold" />
                              }
                            >
                              Edit User
                            </Button>
                          )}
                          {permission.includes('edit role') && (
                            <Button
                              size="small"
                              onClick={() => openModal(row)}
                              style={{
                                justifyContent: 'flex-start',
                                marginTop: 5,
                                width: 165,
                                alignSelf: 'center'
                              }}
                              variant="outlined"
                              component={RouterLink}
                              to="#"
                              startIcon={
                                <Iconify
                                  color={pallete.primary.main}
                                  icon="material-symbols:lock-clock-outline"
                                />
                              }
                            >
                              Edit Role
                            </Button>
                          )}
                          {permission.includes('change password') && (
                            <Button
                              size="small"
                              onClick={() => openResetModal(row)}
                              style={{ justifyContent: 'flex-start', marginTop: 5, width: 165 }}
                              variant="outlined"
                              component={RouterLink}
                              to="#"
                              startIcon={
                                <Iconify color={pallete.primary.main} icon="solar:password-bold" />
                              }
                            >
                              Change Password
                            </Button>
                          )}

                          {permission.includes('change status') && (
                            <Button
                              size="small"
                              onClick={() => openModalStatus(row)}
                              style={{
                                justifyContent: 'flex-start',
                                marginTop: 5,
                                width: 165,
                                alignSelf: 'center',
                                marginRight: 10
                              }}
                              variant="outlined"
                              component={RouterLink}
                              to="#"
                              startIcon={
                                <Iconify
                                  color={pallete.primary.main}
                                  icon="bx:edit"
                                />
                              }
                            >
                              change status
                            </Button>
                          )}

                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isUserNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={search} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && !searchLoading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        {!loading && !searchLoading && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page >
  );
}
