import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography, CircularProgress, Rating } from '@mui/material';
import palette from 'src/theme/palette';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImgsViewer from 'react-images-viewer';
import { api } from 'src/Api';
import { Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';
import { displayName } from 'react-quill';

export default function PropertyDetails({ handleClose, details, refresh }) {
  const self = JSON.parse(localStorage.getItem('user'));
  const { t } = useTranslation();
  const [companyPermission, setcompanyPermission] = useState([]);

  function checkPermission() {
    let requirement = ['visible', 'add', 'edit', 'change agents'];
    let check = 'companies';

    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );

    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setcompanyPermission((permissions) => [
            ...permissions,
            items.permission_type_name.toLowerCase()
          ])
        : false
    );
  }

  const [propertyDetails, setpropertyDetails] = useState(details);
  const [propertyloading, setpropertyloading] = useState(false);

  function getProperty() {
    setpropertyDetails(details);
  }

  const [imagesMultiple, setimagesMultiple] = useState([]);
  const [fullImage, setfullImage] = useState(false);
  const [mainLength, setmainLength] = useState(0);

  function imagesArray() {
    setmainLength(imagesMultiple.length);
    imagesMultiple.length = 0;
    if (propertyDetails?.images?.length > 0) {
      propertyDetails?.images?.map((item, index) => {
        if (item.type == 'images' || item.type == 'image') {
          setimagesMultiple((imagesMultiple) => [
            ...imagesMultiple,
            {
              src: item?.name
            }
          ]);
        }
      });
    }
  }
  useEffect(() => {
    imagesArray();
  }, []);

  useEffect(() => {
    getProperty();
    checkPermission();
  }, []);

  const [index, setindex] = useState(0);

  function gotoPrevious() {
    if (index >= 0 && index <= mainLength) {
      setindex(index - 1);
    }
  }
  function gotoNext() {
    if (index >= 0 && index < mainLength) {
      setindex(index + 1);
    }
  }

  function settingFullImage(index) {
    imagesArray();
    setindex(index);
    setfullImage(true);
  }

  const headingTypography = { fontSize: 14, fontWeight: 'bold', paddingRight: 1 };
  const headingDescription = { fontSize: 14 };

  function ChangeStatus(status, market_status) {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    var formdata = new FormData();
    formdata.append('from_id', Encryption(user?.id, process.env.REACT_APP_SECRET_KEY));
    formdata.append(
      'property_id',
      Encryption(propertyDetails?.id, process.env.REACT_APP_SECRET_KEY)
    );
    formdata.append('status', Encryption(status, process.env.REACT_APP_SECRET_KEY));
    formdata.append('market_status', Encryption(market_status, process.env.REACT_APP_SECRET_KEY));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.save_property_history, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        refresh();
      })
      .catch((error) => console.log('error', error));
  }

  return (
    <Box>
      <>
        {propertyloading ? (
          <Box
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
              height: 500
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box
              pt={2}
              pb={2}
              style={{
                backgroundColor: 'white',
                position: 'sticky',
                top: 0,
                borderBottom: '2px solid',
                borderColor: palette.primary.main,
                zIndex: 5,
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box px={2}>
                <Typography row sx={{ fontSize: 16, fontWeight: 'bold' }} component="div">
                  {propertyDetails?.location}
                </Typography>
                <Typography row sx={{ fontSize: 16, fontWeight: 'bold' }} component="div">
                  {t('PropertyDetail.price')}: {'ZAR'} {propertyDetails?.price}
                </Typography>
              </Box>
              <Box px={2} style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap' }}>
                <Button color="primary" variant="contained" onClick={handleClose} size={'small'}>
                  {t('PropertyDetail.buttons.0')}
                </Button>
              </Box>
            </Box>

            <Box px={4} py={2} className="property_Modal_Padding">
              {/* IMAGE SLIDER WHOLE SCREEN */}
              <ImgsViewer
                imgs={imagesMultiple}
                currImg={index}
                onClickPrev={gotoPrevious}
                onClickNext={gotoNext}
                isOpen={fullImage}
                onClose={() => setfullImage(false)}
              />
              {/* IMAGES ARRAY */}
              {propertyDetails?.images?.filter(
                (item) => item.type == 'images' || item.type == 'image'
              ).length > 0 && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.0')}:
                    </Typography>
                    <Box my={1}>
                      <ImageList
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexWrap: 'wrap'
                        }}
                        cols={5}
                        rowHeight={100}
                        className="justify"
                      >
                        {propertyDetails?.images.map((item, index) =>
                          item.type == 'images' || item.type == 'image' ? (
                            <ImageListItem
                              style={{
                                borderRadius: 15,
                                overflow: 'hidden',
                                border: '2px solid',
                                borderColor: palette.primary.main,
                                backgroundColor: 'silver'
                              }}
                              onClick={() => settingFullImage(index)}
                              key={item?.index}
                            >
                              <img
                                className="image_Width"
                                style={{ width: 90 }}
                                src={item?.name}
                                alt={'Business Image'}
                                loading="lazy"
                              />
                            </ImageListItem>
                          ) : (
                            ''
                          )
                        )}
                      </ImageList>
                    </Box>
                  </Box>
                  <Divider />
                </>
              )}
              {/* DESCRIPTION */}
              {propertyDetails?.description != null && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                    className="column"
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.2')}:
                    </Typography>

                    <Typography
                      row
                      sx={{ ...headingDescription, textAlign: 'justify' }}
                      component="div"
                    >
                      {propertyDetails?.description}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              {/* AREA */}
              {(propertyDetails.length != null ||
                propertyDetails.width != null ||
                propertyDetails.height != null) && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.8')}:
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.length} . {propertyDetails?.width} .{' '}
                      {propertyDetails?.height}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              {propertyDetails.space_type && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      Space Type:
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.space_type}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              {propertyDetails.sub_space_type && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      Sub Space Type:
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.sub_space_type}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              {propertyDetails.property_type && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      Property Type:
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.property_type}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              {propertyDetails.is_vehicle && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      Visiting Available:
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.is_vehicle == '1' ? 'Yes' : 'No'}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              {propertyDetails.vehicle_feature && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      Vehicle Feature:
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.vehicle_feature}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              {propertyDetails.renter_access && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      Renter Access:
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.renter_access}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              {propertyDetails.visit_hour && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      Visiting Hours:
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.visit_hour}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              <Box
                py={1}
                width="100%"
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <Typography sx={headingTypography} component="div">
                  Rating:
                </Typography>
                <Rating name="disabled" value={parseInt(propertyDetails?.rating)} disabled />
                <Divider />
              </Box>

              {/* OPEN INSPECTIONS */}
              {/* {propertyDetails?.open_inspections?.length > 0 && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.22')}
                    </Typography>

                    <Box
                      onClick={() => {
                        setshowShifts(!showShifts);
                      }}
                    >
                      <Iconify
                        style={{ fontSize: 24, marginTop: 5 }}
                        icon={showShifts ? 'mdi:chevron-up' : 'mdi:chevron-down'}
                      />
                    </Box>
                  </Box>
                  {showShifts && (
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                      {propertyDetails?.open_inspections?.map((item) => {
                        return (
                          <Typography sx={{ fontSize: 14, paddingBottom: 1 }} component="div">
                            {`${item.date}    ( ${item.start_time} )   ( ${item.end_time} )`}
                          </Typography>
                        );
                      })}
                    </Box>
                  )}
                  <Divider />
                </>
              )} */}
              {/* OFF PLAN PROJECT */}
              {/* {propertyDetails?.off_plan_project == 1 && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.9')}
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.off_plan_start &&
                        `${t('PropertyDetail.headings.16')} (${propertyDetails?.off_plan_start}) `}
                      {propertyDetails?.off_plan_end &&
                        `${t('PropertyDetail.headings.17')} (${propertyDetails?.off_plan_end}) `}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )} */}
              {/* PROPERTY TYPE & CATEGORY */}
              {/* <>
                <Box
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                  className="column"
                >
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.10')}:
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.proper_category?.name}
                    </Typography>
                  </Box>
                  <Box
                    py={1}
                    width="105%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.11')}:
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.property_type?.name}
                    </Typography>
                  </Box>
                  <Box
                    py={1}
                    width="95%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div"></Typography>
                  </Box>
                </Box>
                <Divider />
              </> */}
              {/* SALE TYPE */}
              {/* {propertyDetails?.sale_type_method != null && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.18')}:
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.sale_type_method?.name}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )} */}
              {/* CONSTRUCTION STATUS */}
              {/* {propertyDetails?.construction_status != null && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.12')}:
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.construction_status?.name}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )} */}
              {/* BUILD IN */}
              {/* {propertyDetails?.year_of_build_check === 1 && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.19')}:
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.year_of_build}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )} */}
              {/* EXCLUSIVE AUTHORITY */}
              {/* {propertyDetails?.exclusive_authority != null && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.20')}:
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.exclusive_authority}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )} */}
              {/* DOCUMENTS */}
              {/* {propertyDetails?.documents.length > 0 && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.21')}
                    </Typography>

                    <Box
                      onClick={() => {
                        setshowDocuments(!showDocuments);
                      }}
                    >
                      <Iconify
                        style={{ fontSize: 24, marginTop: 5 }}
                        icon={showShifts ? 'mdi:chevron-up' : 'mdi:chevron-down'}
                      />
                    </Box>
                  </Box>
                  {showDocuments && (
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                      {propertyDetails?.documents.map((item, index) => {
                        return (
                          <Box
                            py={1}
                            width="100%"
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-between'
                            }}
                          >
                            <Typography sx={headingDescription} component="div">
                              {item?.name}
                            </Typography>

                            <Box
                              onClick={() => {
                                window.open(item?.attachment);
                              }}
                            >
                              <Iconify
                                style={{ fontSize: 24, marginTop: 5, marginRight: 0 }}
                                icon={'material-symbols:download'}
                              />
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                  <Divider />
                </>
              )} */}
              {/* AGENT NAME */}
              {/* <Box
                py={2}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}
              >
                <Box
                  py={1}
                  mr={2}
                  style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <Typography sx={headingTypography} component="div">
                    {t('PropertyDetail.headings.13')}:
                  </Typography>

                  <Typography
                    row
                    sx={headingDescription}
                    component="div"
                    style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
                  >
                    {propertyDetails?.realtor_id?.name}
                  </Typography>
                </Box>
                {self?.id !== propertyDetails?.realtor_id?.id && (
                  <Button
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      window.open(`${chatUrl}${self?.id}/${propertyDetails?.realtor_id?.id}`);
                    }}
                    style={{
                      justifyContent: 'flex-start'
                    }}
                    variant="outlined"
                    component={RouterLink}
                    to="#"
                    startIcon={
                      <Iconify color={pallete.primary.main} icon="material-symbols:chat-outline" />
                    }
                  >
                    {t('Agents.buttons.2')}
                  </Button>
                )}
              </Box> */}
              {/* <Divider /> */}
              {/* STATUS CHANGE */}
              {/* {permission.includes('change status') && (
                <>
                  <Box
                    py={2}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <Typography sx={{ ...headingTypography, width: 150 }} component="div">
                      {t('PropertyDetail.headings.15')}:
                    </Typography>

                    <Box>
                      {propertyDetails?.available_for == 'sale' &&
                        propertyDetails?.status == 'active' && (
                          <Button
                            className="margin"
                            style={button}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              ChangeStatus('sale', propertyDetails?.market_status);
                            }}
                            size={'small'}
                          >
                            {t('PropertyDetail.buttons.1')}
                          </Button>
                        )}
                      {propertyDetails?.available_for == 'rent' &&
                        propertyDetails?.status == 'active' && (
                          <Button
                            className="margin"
                            style={button}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              ChangeStatus('rent', propertyDetails?.market_status);
                            }}
                            size={'small'}
                          >
                            {t('PropertyDetail.buttons.2')}
                          </Button>
                        )}
                      {propertyDetails?.status == 'active' && (
                        <>
                          {propertyDetails?.market_status !== 'Under Construction' && (
                            <Button
                              className="margin"
                              style={button}
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                ChangeStatus('active', 'Under Construction');
                              }}
                              size={'small'}
                            >
                              {t('PropertyDetail.buttons.3')}
                            </Button>
                          )}

                          {propertyDetails?.market_status !== 'Under Offer' && (
                            <Button
                              className="margin"
                              style={button}
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                ChangeStatus('active', 'Under Offer');
                              }}
                              size={'small'}
                            >
                              {t('PropertyDetail.buttons.4')}
                            </Button>
                          )}
                          {propertyDetails?.market_status !== 'Off Market' && (
                            <Button
                              className="margin"
                              style={button}
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                ChangeStatus('active', 'Off Market');
                              }}
                              size={'small'}
                            >
                              {t('PropertyDetail.buttons.5')}
                            </Button>
                          )}
                        </>
                      )}

                      {(propertyDetails?.status !== 'active' ||
                        propertyDetails?.market_status !== 'active') && (
                        <Button
                          className="margin"
                          style={button}
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            ChangeStatus('active', 'active');
                          }}
                          size={'small'}
                        >
                          {t('PropertyDetail.buttons.6')}
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Divider />
                </>
                    */}
            </Box>
            <Box
              style={{
                // height: '310px',
                alignSelf: 'center',
                justifyContent: 'center',
                display: 'flex'
              }}
            >
              <iframe
                style={{ width: '95%', height: '350px' }}
                src={
                  'https://maps.google.com/maps?key=AIzaSyCfkkFnJ7ixENdtACx3-Q5Mewh3wftkCo8&q=' +
                  propertyDetails?.lat +
                  ',' +
                  propertyDetails?.lon +
                  '&hl=es;z=14&output=embed'
                }
              ></iframe>
            </Box>
          </>
        )}
      </>
    </Box>
  );
}
