import React, { useState } from 'react';
import {
  Box,
  Grid,
  Button,
  CardContent,
  Divider,
  TextField,
  Typography,
  Stack,
  Avatar
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import { api } from '../../../Api';
import { Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';

export default function EditUserModal({ handleClose, user, refreshAdmin }) {
  const { t } = useTranslation();
  const [loading, setloading] = useState(false);
  const [alert, setalert] = useState({ visible: false, message: '' });

  function SaveProfile() {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();

    formdata.append('name', Encryption(firstName, process.env.REACT_APP_SECRET_KEY));
    formdata.append('id', user?.id);
    formdata.append('email', Encryption(email, process.env.REACT_APP_SECRET_KEY));
    formdata.append('contact_no', Encryption(contact, process.env.REACT_APP_SECRET_KEY));

    if (file) {
      formdata.append('avatar', file.file);
    }

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    setloading(true);

    fetch(api.updateProfile, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const response = JSON.parse(result);
        if (response.success === true) {
          refreshAdmin();
        } else {
          setalert({ visible: true, message: Object.values(response?.message)[0][0] });
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => setloading(false));
  }

  const [file, setfile] = useState({ image: user.avatar, file: '' });
  const [firstName, setfirstName] = useState(user.name);
  const [email, setemail] = useState(user.email);
  const [contact, setcontact] = useState(user.contact_no);

  const [objAfter, setobjAfter] = useState({
    firstName: user?.name,
    email: user?.email,
    contact: user?.contact_no,
    file: false
  });

  return (
    <Box p={1} mt={2}>
      <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
        {t('EditProfile.name')}
      </Typography>

      <CardContent>
        <Stack
          mb={2}
          direction={{ xs: 'column' }}
          style={{ display: 'flex', flexDirection: 'row' }}
          className="image_center"
        >
          <Box
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {file && (
              <Avatar
                alt="Remy Sharp"
                src={objAfter?.file ? file.image : file.image + `?v='${Date.now()}`}
                sx={{ width: 80, height: 80 }}
                className="image_center"
              />
            )}
            <Button
              style={{
                width: !file ? 80 : null,
                height: !file ? 80 : 30,
                borderRadius: !file ? 40 : 20,
                marginTop: 10,
                marginBottom: 10,
                fontSize: 12
              }}
              variant={file ? 'outlined' : 'outlined'}
              component="label"
            >
              {t('EditProfile.buttons.0')}
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => {
                  setobjAfter({
                    file: true
                  });
                  setfile({
                    image: URL.createObjectURL(e.target.files[0]),
                    file: e.target.files[0]
                  });
                }}
              />
            </Button>
          </Box>
        </Stack>
        <Grid container spacing={2} className="top_column">
          {user?.role !== 4 && (
            <>
              <Grid item lg={6} sm={12} md={12}>
                <TextField
                  id="outlined-error-helper-text"
                  fullWidth
                  label={'Name'}
                  type="text"
                  value={firstName}
                  onChange={(val) => {
                    setfirstName(val.target.value);
                  }}
                  variant="outlined"
                  display="inline"
                />
              </Grid>
            </>
          )}

          <Grid item lg={6} sm={12} md={12}>
            <TextField
              id="outlined-error-helper-text"
              fullWidth
              label={t('EditProfile.fields.2')}
              value={email}
              onChange={(val) => {
                setemail(val.target.value);
              }}
              type="text"
              variant="outlined"
              display="inline"
            />
          </Grid>
          <Grid item lg={12} sm={12} md={12}>
            <TextField
              id="outlined-error-helper-text"
              fullWidth
              label={t('EditProfile.fields.3')}
              name="contact"
              value={contact}
              onChange={(val) => {
                setcontact(val.target.value);
              }}
              type="tel"
              variant="outlined"
              display="inline"
            />
          </Grid>
        </Grid>
      </CardContent>

      {alert && alert.visible && <Alert severity="error">{alert.message}</Alert>}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <LoadingButton
          disabled={false}
          loading={loading}
          sx={{
            marginRight: 1
          }}
          onClick={() => {
            SaveProfile();
          }}
          color="primary"
          variant="contained"
        >
          {t('EditProfile.buttons.1')}
        </LoadingButton>
        <LoadingButton
          sx={{
            marginRight: 1
          }}
          onClick={handleClose}
          variant="outlined"
        >
          {t('EditProfile.buttons.2')}
        </LoadingButton>
      </Box>
    </Box>
  );
}
