import { useRef, useState, useEffect } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'en',
    label: 'English',
    icon: '/static/icons/ic_flag_en.svg'
  },
  {
    value: 'zh',
    label: 'Chinese',
    icon: 'https://i.ibb.co/YZPxHqd/chinese.jpg'
  }
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const { t, i18n } = useTranslation();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedLangugage, setselectedLangugage] = useState(LANGS[0]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    console.log(e);
    if (e?.value) {
      setselectedLangugage(e);
      i18n.changeLanguage(e?.value);
      localStorage.setItem('language', e?.value);
    }
    setOpen(false);
  };

  useEffect(() => {
    const lang = localStorage.getItem('language');

    if (lang != null && lang != undefined) {
      const selectedLanguage = LANGS.find((item) => item.value === lang);
      if (selectedLanguage) {
        setselectedLangugage(selectedLanguage);
      }
    }
  }, []);

  return (
    <>
      {/* {selectedLangugage && (
        <IconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            objectFit: 'contain',
            padding: 0,
            width: 44,
            height: 44,
            ...(open && {
              bgcolor: (theme) =>
                alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
            })
          }}
        >
          <img
            src={selectedLangugage?.icon}
            alt={selectedLangugage?.label}
            style={{ width: 50, height: 20, objectFit: 'contain' }}
          />
        </IconButton>
      )} */}

      {/* <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current}> */}
      {/* <Box sx={{ py: 1 }}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={selectedLangugage}
              onClick={() => handleClose(option)}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                <img src={option.icon} style={{ width: 50, height: 20, objectFit: 'contain' }} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box> */}
      {/* </MenuPopover> */}
    </>
  );
}
