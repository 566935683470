import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, Button, Box, Checkbox, Typography, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import { api } from 'src/Api';
import { Decryption, Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function RoleModal({ handleClose, refresh, edit, roleid }) {
  const { t } = useTranslation();
  const [loading, setloading] = useState(false);
  const RegisterSchema = Yup.object().shape({
    roleName: Yup.string().required(t('RoleModal.required.0'))
  });

  const formik = useFormik({
    initialValues: {
      roleName: edit ? roleid?.name : ''
    },
    validationSchema: RegisterSchema,
    onSubmit: () => (edit ? AssignRoleToPermission(roleid.id) : CreateEditRole())
  });

  useEffect(() => {
    getPermissions();
  }, []);

  const [permissionList, setpermissionList] = useState([]);
  const [oldArray, setoldArray] = useState([]);

  function getPermissions() {
    setloading(true);

    var myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    edit && formdata.append('role_id', Encryption(roleid?.id, process.env.REACT_APP_SECRET_KEY));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.get_permissions, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.success === true) {
          setpermissionList(result.data);
          if (edit) {
            result.data.map((item) => {
              if (item.permission_types.length > 0) {
                item.permission_types.map((items) => {
                  let id = JSON.stringify({ permissionId: item.id, typeId: items.id });
                  setcheckArray((items) => [...items, id]);
                  setoldArray((items) => [...items, id]);
                });
              }
            });
          }
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => setloading(false));
  }

  function CreateEditRole() {
    setloading(true);
    var myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    formdata.append(
      'role_name',
      Encryption(formik.values.roleName, process.env.REACT_APP_SECRET_KEY)
    );
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.roles, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          let decryptedData = result.data;
          AssignRoleToPermission(decryptedData.id);
        } else {
          setalert({ visible: true, message: 'Something went wrong please try again' });
        }
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => setloading(false));
  }

  function AssignRoleToPermission(id) {
    setloading(true);
    const token = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        role_id: Encryption(id, process.env.REACT_APP_SECRET_KEY),
        values: Encryption(JSON.stringify(checkArray), process.env.REACT_APP_SECRET_KEY)
      }),
      redirect: 'follow'
    };

    fetch(api.add_role_with_per_and_type, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          refresh();
        } else {
          setalert({ visible: true, message: 'Something went wrong please try again' });
          setloading(false);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => setloading(true));
  }

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const [alert, setalert] = useState({ visible: false, message: '' });

  const [checkArray, setcheckArray] = useState([]);

  function CheckMark(id) {
    let obj = JSON.parse(id);
    //checking if exists already
    if (checkArray.includes(id)) {
      //if permission visible
      if (obj.typeId == 1) {
        setcheckArray(
          checkArray.filter((item) => {
            let object = JSON.parse(item);
            return object.permissionId !== obj.permissionId;
          })
        );
      } else {
        setcheckArray(checkArray.filter((item) => item != id));
      }
    } else {
      setcheckArray([...checkArray, id]);
    }
  }

  return (
    <FormikProvider value={formik}>
      <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
        {t('RoleModal.name')}
      </Typography>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: 20 }}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              disabled={edit}
              fullWidth
              label={t('RoleModal.role')}
              {...getFieldProps('roleName')}
              error={Boolean(touched.roleName && errors.roleName)}
              helperText={touched.roleName && errors.roleName}
            />
          </Stack>

          <Typography style={{ fontWeight: 'bold', marginBottom: 0 }} variant="subtitle1" noWrap>
            {t('RoleModal.permission')}
          </Typography>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {permissionList.length > 0 && (
              <>
                {permissionList.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItem: 'center',
                        flex: 1,
                        paddingTop: 5,
                        paddingBottom: 5
                      }}
                    >
                      <Typography
                        sx={{
                          width: 190,
                          fontWeight: 'bold'
                        }}
                      >
                        {item.permission_name}
                      </Typography>

                      {item.allowed_permission_types.map((items, indexx) => {
                        let id = JSON.stringify({ permissionId: item.id, typeId: items.id });
                        return (
                          <Box
                            sx={{
                              flexDirection: 'row',
                              display: 'flex',
                              alignItems: 'center',
                              width: 165
                            }}
                          >
                            {indexx == 0 && <Typography>{':  '}</Typography>}
                            <Checkbox
                              sx={{
                                marin: 0,
                                padding: 0,
                                marginLeft: 3,
                                marginRight: 1
                              }}
                              disabled={
                                indexx == 0
                                  ? false
                                  : checkArray.includes(
                                      JSON.stringify({ permissionId: item.id, typeId: 1 })
                                    )
                                  ? false
                                  : true
                              }
                              checked={checkArray.includes(id)}
                              onChange={() => {
                                CheckMark(id);
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: 12
                              }}
                            >
                              {items.permission_type_name}
                              {/* {t(items.translation)} */}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  );
                })}
              </>
            )}
          </Box>

          {alert.visible && (
            <>
              <Alert
                onClose={() => {
                  setalert({ visible: false, message: '' });
                }}
                severity="error"
              >
                {alert.message}
              </Alert>
            </>
          )}
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <LoadingButton
              disabled={
                oldArray.toString() == checkArray.toString() || checkArray.length < 1 ? true : false
              }
              sx={{ marginRight: 1 }}
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
            >
              {t('RoleModal.buttons.0')}
            </LoadingButton>
            <Button size="large" onClick={handleClose} variant="outlined">
              {t('RoleModal.buttons.1')}
            </Button>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
