import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from '@mui/material';
import { api } from 'src/Api';

export default function RoleChangeDropdown({ setRole, fullWidth, error, dontAppend, alreadyRole }) {
  const [roles, setRoles] = useState([]);
  const [reasondrop, setReasondrop] = useState(null);

  useEffect(() => {
    const getRoles = async () => {
      const myHeaders = new Headers();
      const token = localStorage.getItem('token');
      myHeaders.append('Authorization', `Bearer ${token}`);

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
      };

      try {
        const response = await fetch(api.getRoleNames, requestOptions);
        const result = await response.json();
        if (result.success) {
          const rolesData = dontAppend
            ? result.data
            : [{ id: 0, role_name: 'All' }, ...result.data];
          setRoles(rolesData);
          if (alreadyRole) {
            const defaultRole = rolesData.find((role) => role.id === alreadyRole.id) || null;
            setReasondrop(defaultRole);
          }
        }
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    getRoles();
  }, [alreadyRole, dontAppend]);

  const handleChange = (event) => {
    const selectedRole = event.target.value;
    setRole(selectedRole?.id);
    setReasondrop(selectedRole);
  };

  return (
    <TextField
      error={error}
      color={error ? 'error' : 'primary'}
      fullWidth={fullWidth}
      style={{ width: !fullWidth && '20%', marginLeft: !fullWidth && 15 }}
      select
      value={reasondrop}
      label={''}
      onChange={handleChange}
    >
      {roles.map((option) => (
        <MenuItem key={option.id} value={option}>
          {option.role_name}
        </MenuItem>
      ))}
    </TextField>
  );
}
