export const mainurl = process.env.REACT_APP_MAINURL;
export const chatUrl = process.env.REACT_APP_CHATURL;

export const api = {
  login: `${mainurl}loginwithrole`,
  register: `${mainurl}register`,
  getUsers: `${mainurl}get_users`,
  getInqueries: `${mainurl}get_inqueries`,
  getFeedback: `${mainurl}get_product_feedback`,
  change_documents_status: `${mainurl}change_documents_status`,
  getStatus: `${mainurl}get_inquiry_status`,
  getFaqCategory: `${mainurl}get-categories`,
  saveFaqCategory: `${mainurl}save-categories`,
  deleteFaqCategory: `${mainurl}delete-categories`,
  get_wallet_for_admin: `${mainurl}get_wallet_for_admin`,
  get_pending_requests: `${mainurl}get_pending_requests`,
  getFaq: `${mainurl}get-help`,
  saveFaq: `${mainurl}save-help`,
  deleteFaq: `${mainurl}remove-help`,
  get_agents: `${mainurl}get_realtor`,
  getCompany: `${mainurl}get_companies`,
  getTypes: `${mainurl}get_property_types`,
  getSaleType: `${mainurl}get_sale_type_method`,
  getConstructionStatus: `${mainurl}get_construction_status`,
  getAreaUnit: `${mainurl}get_area_unit`,
  getSortBy: `${mainurl}get_sortby`,
  UpdateUserStatus: `${mainurl}company_user_status_update`,
  getBusinesses: `${mainurl}get_property_external`,
  getOffers: `${mainurl}getbusinessoffers`,
  reset_password: `${mainurl}changePassword`,
  sendNotification: `${mainurl}sendnotification`,
  approveProperty: `${mainurl}approved_property`,
  approveRealtor: `${mainurl}approved_realtor`,
  approveOffers: `${mainurl}approve-businessoffers`,
  getbusinessoffers: `${mainurl}getbusinessoffers`,
  createAgent: `${mainurl}register`, //get-dashboard
  getDashboard: `${mainurl}get-dashboard`, //get-dashboard,
  getRequests: `${mainurl}get_inquiry`,
  getDocuments: `${mainurl}get_documents`,
  uploadDocument: `${mainurl}save_documents`,
  getHelp: `${mainurl}get-help`,
  saveHelp: `${mainurl}save-help`,
  getHelpCateogries: `${mainurl}get-categories`,
  getDocumentType: `${mainurl}get-document-type`,
  markasread: `${mainurl}editrequest`,
  getPosts: `${mainurl}get_posts`,
  postApprove: `${mainurl}update_posts_status`,
  sendEmail: `${mainurl}sendemail`,
  allseen: `${mainurl}all_seen`,
  //ROLE PERMISSIONS
  getRoles: `${mainurl}get_role_permission_and_types`,
  get_permissions: `${mainurl}get_permissions`,
  roles: `${mainurl}roles`,
  add_role_with_per_and_type: `${mainurl}add_role_with_per_and_type`,
  delete_roles: `${mainurl}delete_roles`,
  saveProfile: `${mainurl}allowed_realtors_edit`,
  updateProfile: `${mainurl}update_profile`,
  replyInqueries: `${mainurl}inquiry_reply_email`,
  inquiresStatusChange: `${mainurl}inquiry_status_change`,
  getRoleNames: `${mainurl}get_roles`,
  assignRole: `${mainurl}assign_role`,
  status: `${mainurl}status`,
  change_wallet_status: `${mainurl}change_wallet_status`,
  update_user_status: `${mainurl}update_user_status`,
  reservation_status_change: `${mainurl}reservation_status_change`,
  terminateadmin: `${mainurl}team_status_change`,
  resend_access: `${mainurl}resend_access`,
  admin_logout: `${mainurl}admin_logout`,
  user_roles: `${mainurl}user_roles`,
  //SideBar and Permission
  sideBar: `${mainurl}get_role_permission_by_user`,
  //team_status_change
  getDocumentType: `${mainurl}get-document-type`,
  // get-document-type
  //CITY AND STATES SEARCHABLE
  getcityandstates: `${mainurl}getcityandstates`,
  //Business Category
  getPropertyCategories: `${mainurl}get_categories`,
  // offer freq nd types
  getoffertype: `${mainurl}getoffertype`,
  getofferfrequency: `${mainurl}getofferfrequency`, //get-categories
  getUsersdocuments: `${mainurl}accept_user_list_documents`,
  getCategories: `${mainurl}get-categories`,
  get_event_logs: `${mainurl}get_event_logs`,
  getbusinessesbyid: `${mainurl}getbusinessesbyid`,
  getFeatures: `${mainurl}get_about`,
  delete_report_pictures: `${mainurl}delete_report_pictures`,
  user_terminate: `${mainurl}user_terminate`,
  property_realtor_change: `${mainurl}property_realtor_change`,
  save_property_history: `${mainurl}save_property_history`,
  company_realtor_edit: `${mainurl}company_realtor_edit`,
  get_property_types: `${mainurl}get_property_types`,
  get_construction_status: `${mainurl}get_construction_status`,
  upload_property_images: `${mainurl}upload_property_images`,
  save_properties: `${mainurl}save_properties`,
  upload_property_documents: `${mainurl}upload_property_documents`,
  get_company_contacts: `${mainurl}get_company_contacts`,
  save_company_contacts: `${mainurl}save_company_contacts`,
  delete_company_contacts: `${mainurl}delete_company_contacts`,
  delete_documents: `${mainurl}delete_documents`,
  save_property_comments: `${mainurl}save_property_comments`,
  get_property_comments: `${mainurl}get_property_comments`,
  delete_property_comments: `${mainurl}delete_property_comments`,
  allowed_realtors: `${mainurl}allowed_realtors_edit`,
  edit_property_documents: `${mainurl}edit_property_documents`,
  updateforgotpassword: `${mainurl}updateforgotpassword`,
  sendforgotpasswordweb: `${mainurl}sendforgotpasswordweb`,
  send_forgot_password: `${mainurl}sendforgotpassword`,
  check_code_validity: `${mainurl}check_code_validity`,
  getProperties: `${mainurl}get_listings`,
  get_property_status: `${mainurl}get_property_status`, //update_listing_status
  update_listing_status: `${mainurl}update_listing_status`, //update_listing_status
  get_reservation_history: `${mainurl}get_reservation_history`, //update_listing_status
  delete_reservation: `${mainurl}delete_reservation`,
  get_transactions: `${mainurl}get_transactions` //update_listing_status
};
