import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Button, Box, Divider, MenuItem, Typography, Modal } from '@mui/material';
// import palette from 'src/theme/palette';

// components
import Iconify from '../../components/Iconify';
import MenuPopover from '../../components/MenuPopover';
import { api } from 'src/Api';
import { AccountModal } from 'src/sections/@dashboard/myaccount';
import { UserPassword } from 'src/sections/@dashboard/user';
import pallete from '../../theme/palette';
import { useTranslation } from 'react-i18next';
//

import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { t } = useTranslation();

  const MENU_OPTIONS = [
    {
      label: t('dashboard.Account.1'),
      icon: 'eva:person-fill',
      linkTo: '#'
    },
    {
      label: t('dashboard.Account.2'),
      icon: 'solar:password-linear',
      linkTo: '#'
    }
  ];

  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    //   setaccountModal(true);
  };

  const user = JSON.parse(localStorage.getItem('user'));

  const [accountModal, setaccountModal] = useState(false);
  const handleAdminClose = () => {
    setOpen(false);
    setaccountModal(false);
  };
  const refreshAdmin = () => {
    setaccountModal(false);
  };

  const style = {
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: '2px solid',
    borderColor: pallete.primary.main,
    overflow: 'hidden'
  };

  const boxStyle = {
    minWidth: '70%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    p: 4,
    border: '2px solid',
    borderColor: pallete.primary.main
  };

  const [loading, setloading] = useState(false);
  function Logout() {
    const rememberEmail = localStorage.getItem('email');
    const rememberPassword = localStorage.getItem('password');
    const rememberMe = localStorage.getItem('remember');

    // Clear all local storage
    localStorage.clear();

    // Re-set remembered credentials
    if (rememberMe === 'true') {
      localStorage.setItem('email', rememberEmail);
      localStorage.setItem('password', rememberPassword);
      localStorage.setItem('remember', rememberMe);
    }

    navigate('/login', { replace: true });
  }

  const [reset, setreset] = useState(false);
  return (
    <>
      {user != null && (
        <>
          <Modal
            style={{
              display: 'flex',
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            open={accountModal}
            onClose={handleAdminClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{ ...boxStyle, style }} className="admin_modal_pa">
              <AccountModal handleClose={handleAdminClose} refresh={refreshAdmin} />
            </Box>
          </Modal>
          <Modal
            style={{
              display: 'flex',
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            open={reset}
            onClose={handleAdminClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                ...style,
                p: 0,
                width: '70%',
                minHeight: '60%',
                alignItems: 'center'
              }}
            >
              <UserPassword
                handleClose={() => {
                  setreset(false);
                }}
                refresh={() => {
                  setreset(false);
                }}
              />
            </Box>
          </Modal>

          <Button
            variant="contained"
            ref={anchorRef}
            onClick={handleOpen}
            to="#"
            startIcon={<Iconify icon={'gg:profile'} />}
          >
            {t('dashboard.Account.0')}
          </Button>

          <MenuPopover
            open={open}
            onClose={handleClose}
            anchorEl={anchorRef.current}
            sx={{ width: 220 }}
          >
            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Typography variant="subtitle1" noWrap>
                {user?.firstname} {user?.lastname}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {user?.email}
              </Typography>
            </Box>

            <Divider sx={{ my: 1 }} />

            {MENU_OPTIONS.map((option) => (
              <>
                <MenuItem
                  key={option.label}
                  to={option.linkTo}
                  component={RouterLink}
                  onClick={() => {
                    if (option.label === t('dashboard.Account.1')) {
                      setOpen(false);
                      setaccountModal(true);
                    } else {
                      setOpen(false);
                      setreset(true);
                    }
                  }}
                  sx={{ typography: 'body2', py: 1, px: 2.5 }}
                >
                  <Iconify
                    icon={option.icon}
                    sx={{
                      mr: 2,
                      width: 24,
                      height: 24
                    }}
                  />

                  {option.label}
                </MenuItem>
                <Divider py={0} my={0} />
              </>
            ))}

            <Box sx={{ p: 2, pt: 1.5 }}>
              <LoadingButton
                onClick={() => Logout()}
                fullWidth
                color="inherit"
                variant="outlined"
                loading={loading}
              >
                {t('dashboard.Account.3')}
              </LoadingButton>
            </Box>
          </MenuPopover>
        </>
      )}
    </>
  );
}
