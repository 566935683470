import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill')
  },
  {
    title: 'Role/Permission',
    path: '/dashboard/roles',
    icon: getIcon('material-symbols:lock-clock-outline')
  },
  {
    title: 'team',
    path: '/dashboard/team',
    icon: getIcon('eos-icons:admin')
  },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill')
  },
  {
    title: 'properties',
    path: '/dashboard/properties',
    icon: getIcon('eva:shopping-bag-fill')
  },
  {
    title: 'offers',
    path: '/dashboard/offers',
    icon: getIcon('bxs:offer')
  },
  {
    title: 'I want',
    path: '/dashboard/posts',
    icon: getIcon('bi:file-post')
  },
  {
    title: 'documents',
    path: '/dashboard/documents',
    icon: getIcon('carbon:document-add')
  },
  {
    title: 'Inquiry',
    path: '/dashboard/inquiry',
    icon: getIcon('ant-design:user-add-outlined')
  },
  {
    title: 'help center',
    path: '/dashboard/helpcenter',
    icon: getIcon('fa6-solid:file-circle-question')
  },
  {
    title: 'Company',
    path: '/dashboard/companies',
    icon: getIcon('eva:people-fill')
  }
];

export default sidebarConfig;
