import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CryptoJS from 'crypto-js';
import Alert from '@mui/material/Alert';
import { Encryption } from 'src/functions/function';
import { api } from 'src/Api';

// ----------------------------------------------------------------------//

export default function RegisterForm() {
  const navigate = useNavigate();
  const [alert, setalert] = useState({ visible: false, message: '', color: 'success' });
  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      console.log(process.env.REACT_APP_SECRET_KEY);
      //   window.alert(process.env.REACT_APP_SECRET_KEY);
      sendLink();
    }
  });

  const { errors, values, touched, handleSubmit, getFieldProps } = formik;
  const [loading, setloading] = useState(false);

  function sendLink() {
    let code = Math.floor(Math.random() * 90000) + 10000;

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        email: Encryption(values.email, process.env.REACT_APP_SECRET_KEY),
        code: code
      }),
      redirect: 'follow'
    };

    setloading(true);

    fetch(api.send_forgot_password, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        setloading(false);
        const result = JSON.parse(result_);
        if (result.success === true) {
          localStorage.setItem('code', code.toString());
          localStorage.setItem('email', values.email);
          setTimeout(() => {
            navigate('/otpVerification', { replace: true });
          }, 400);

          setalert({ visible: true, message: result.message, color: 'success' });
        } else {
          setalert({ visible: true, message: result.message, color: 'error' });
        }
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setloading(false);
      });
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            required
            fullWidth
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          {alert.visible && (
            <Alert
              onClose={() => {
                setalert({ visible: false, message: '', color: 'success' });
              }}
              severity={alert?.color}
            >
              {alert.message}
            </Alert>
          )}

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
            Send
          </LoadingButton>
          <LoadingButton
            style={{ marginTop: 10 }}
            fullWidth
            size="large"
            type="submit"
            variant="outlined"
            onClick={() => {
              navigate(-1);
            }}
          >
            Login
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
