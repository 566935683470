import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  CircularProgress,
  Pagination,
  Alert,
  Grid,
  Divider,
  Tooltip,
  IconButton
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { Encryption } from 'src/functions/function';
import Label from 'src/components/Label';

// components
import { api } from '../Api';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';
import moment from 'moment';
import StatusDropDown from 'src/layouts/dashboard/StatusDropDown';
import pallete from '../theme/palette';

export default function Wallet() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { id: 'name', label: t('Users.table.0'), alignRight: false },
    { id: 'email', label: t('Users.table.1'), alignRight: false },
    { id: 'funds_available', label: 'Funds (ZAR)', alignRight: false },
    { id: 'withdraw_requested', label: 'Requested (ZAR)', alignRight: false },
    { id: 'onhold', label: 'Onhold (ZAR)', alignRight: false },
    { id: 'declined', label: 'Declined (ZAR)', alignRight: false },
    { id: 'upcomming', label: 'Upcomming (ZAR)', alignRight: false },
    { id: 'cancelled', label: 'Cancelled (ZAR)', alignRight: false },
    { id: 'withdrawn', label: 'Withdrawn (ZAR)', alignRight: false },
    // { id: 'status', label: 'Status', alignRight: false },
    { id: 'action', label: "Actions", alignRight: false }
  ];
  const TABLE_HEAD_TRANSACTION = [
    { id: 'name', label: t('Users.table.0'), alignRight: false },
    { id: 'email', label: t('Users.table.1'), alignRight: false },
    { id: 'start date', label: 'Start Date', alignRight: false },
    { id: 'end date', label: 'End date', alignRight: false },
    { id: 'amount', label: 'Amount', alignRight: false },
    // { id: 'status', label: 'Status', alignRight: false },
  ];
  const TABLE_HEAD_TRANSACTION3 = [
    { id: 'name', label: t('Users.table.0'), alignRight: false },
    { id: 'withdraw_requested', label: 'Withdraw Requested (ZAR)', alignRight: false },
    { id: 'onhold', label: 'Onhold (ZAR)', alignRight: false },
    { id: 'declined', label: 'Declined (ZAR)', alignRight: false },
    { id: 'approve', label: 'Approved (ZAR)', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'action', label: "Actions", alignRight: false }
  ];

  const [permission, setpermission] = useState([]);
  const requirement = ['visible', 'transactions details', 'change status', 'pending request'];

  const [list, setlist] = useState([]);
  const [list2, setlist2] = useState([]);
  const [userData, setData] = useState(null);
  const [search, setsearch] = useState('');
  const [page, setPage] = React.useState(1);
  const [loading, setloading] = useState(true);
  const [pagesCount, setpagesCount] = useState(1);
  const [selectedList, setSelectedList] = useState([]);
  const [searchLoading, setsearchLoading] = useState(false);
  const [selectedButton, setSelectedButton] = useState('available');
  const [OpenTransactionModal, setOpenTransactionModal] = React.useState(false);
  const [OpenTransactionModal2, setOpenTransactionModal2] = React.useState(false);
  const [alertSuccess, setalertSuccess] = useState({ visible: false, message: '' });
  const [alertError, setalertError] = useState({ visible: false, message: '' });
  const [status, setstatus] = useState(null);

  const isUserNotFound = list.length === 0;

  const handleOpenTransactionModal = () => setOpenTransactionModal(true);
  const handleOpenTransactionModal2 = () => setOpenTransactionModal2(true);
  const handleCloseTransactionModal = () => { setOpenTransactionModal(false); setSelectedButton("available") }
  const handleCloseTransactionModal2 = () => { setOpenTransactionModal2(false) }

  useEffect(() => {
    if (search.length == 0) {
      list.length = 0;
      getWallets();
    }
  }, [search]);

  useEffect(() => {
    checkRender();
  }, []);

  useEffect(() => {
    getWallets();
  }, []);

  useEffect(() => {
    list.length = 0;
    getWallets();
  }, [status]);

  useEffect(() => {
    getPendingRequest();
  }, [userData]);

  useEffect(() => {
    if (userData) {
      if (selectedButton === "available") {
        setSelectedList(userData?.transactions?.available)
      } else if (selectedButton === "withdraw_requested") {
        setSelectedList(userData?.transactions?.withdraw_requested)
      } else if (selectedButton === "withdrawn") {
        setSelectedList(userData?.transactions?.withdrawn)
      } else if (selectedButton === "onhold") {
        setSelectedList(userData?.transactions?.onhold)
      } else if (selectedButton === "declined") {
        setSelectedList(userData?.transactions?.declined)
      } else if (selectedButton === "upcomming") {
        setSelectedList(userData?.transactions?.upcomming)
      } else if (selectedButton === "cancelled") {
        setSelectedList(userData?.transactions?.cancelled)
      }
    }
  }, [userData, selectedButton]);

  function getWallets(page) {
    setloading(true);

    const myHeaders = new Headers();
    const token = localStorage.getItem('token');

    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();

    search.length > 0 &&
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_KEY));
    page && formdata.append('page', Encryption(page, process.env.REACT_APP_SECRET_KEY));
    status &&
      status != 'All' &&
      formdata.append('status', Encryption(status, process.env.REACT_APP_SECRET_KEY));

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_wallet_for_admin, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          list.length = 0;
          let decryptedData = result.data;
          setlist(decryptedData);
          result?.count ? setpagesCount(Math.ceil(result?.count / 10)) : setpagesCount(1);
        } else {
          setlist([]);
          setpagesCount(0);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
        setsearchLoading(false);
      });
  }

  function getPendingRequest() {
    // setloading(true);

    const myHeaders = new Headers();
    const token = localStorage.getItem('token');

    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    formdata.append('user_id', userData?.user?.id);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_pending_requests, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          list2.length = 0;
          let decryptedData = result.data;
          setlist2(decryptedData);
        } else {
          setlist2([]);
          setpagesCount(0);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
      });
  }

  function handleChangeStatus(statuss, id) {
    setloading(true);
    const myHeaders = new Headers();

    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append('user_id', userData?.user?.id);
    formdata.append('status', statuss);
    formdata.append('pending_request_id', id);
    console.log('formdata', formdata)

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.change_wallet_status, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          refreshAdmin()
          setalertSuccess({ visible: true, message: result.message });
          setTimeout(() => {
            setalertSuccess({ visible: false, message: result.message });
            handleCloseTransactionModal2()
            setData('');
          }, 2000);
        } else {
          setalertError({ visible: true, message: result.message });
          console.log("🚀 ~ .then ~ result:", result)
          setTimeout(() => {
            setalertError({ visible: false, message: result.message });
          }, 2000);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
      });
  }

  const refreshAdmin = () => {
    getWallets(page);
  };

  const handleChangeStatusFunction = (statuss, id) => {
    handleChangeStatus(statuss, id)
  };

  function checkRender() {
    let check = 'wallet';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  const formatNumberWithCommas = (value) => {
    if (value === null || value === undefined || isNaN(Number(value))) return "0";
    const number = Number(value);
    return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    getWallets(value);
  };

  function openTransactionModal(user) {
    setData(user);
    handleOpenTransactionModal();
  }

  function openTransactionModal2(user) {
    setData(user);
    handleOpenTransactionModal2();
  }

  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
    height: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
    border: '2px solid',
    borderColor: 'primary.main',
    overflow: 'hidden'
  };

  const boxStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    maxWidth: '40%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    p: 4,
    border: '2px solid',
    borderColor: 'primary.main'
  };

  return (
    <Page title={`Wallet | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>

        {/* DETAILS */}
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
          open={OpenTransactionModal}
          onClose={handleCloseTransactionModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle, style, maxWidth: '80%' }} className="admin_modal_pa">
            <Box p={3} flexDirection={'column'} display={'flex'} flex={1}>

              <Box marginTop={2} flexDirection={'row'} display={'flex'} flex={1}>
                <Grid container className="top_column" sx={{ maxWidth: "98.5%", display: "flex", justifyContent: "space-between", alignItems: 'center', }}>

                  <Button
                    size="medium"
                    disabled={userData?.transactions?.available?.length > 0 ? false : true}
                    variant='outlined'
                    onClick={() => setSelectedButton("available")}
                    style={{
                      justifyContent: 'center',
                      alignItems: "center",
                      marginTop: 5,
                      width: 115,
                      alignSelf: 'center',
                      backgroundColor: selectedButton === "available" ? 'black' : 'transparent',
                      borderWidth: selectedButton === "available" ? 0 : 1,
                      borderColor: "black",
                      color: selectedButton === "available" ? 'white' : 'black',
                      fontSize: 10
                    }}
                  >
                    {`available (${userData?.transactions?.available?.length})`}
                  </Button>

                  <Button
                    size="medium"
                    onClick={() => setSelectedButton("withdraw_requested")}
                    variant='outlined'
                    disabled={userData?.transactions?.withdraw_requested?.length > 0 ? false : true}
                    style={{
                      justifyContent: 'center',
                      alignItems: "center",
                      marginTop: 5,
                      width: 155,
                      alignSelf: 'center',
                      backgroundColor: selectedButton === "withdraw_requested" ? 'black' : 'transparent',
                      borderWidth: selectedButton === "withdraw_requested" ? 0 : 1,
                      borderColor: "black",
                      color: selectedButton === "withdraw_requested" ? 'white' : 'black',
                      fontSize: 10
                    }}
                  >
                    {`Withdraw Requested (${userData?.transactions?.withdraw_requested?.length})`}
                  </Button>

                  <Button
                    size="medium"
                    variant='outlined'
                    disabled={userData?.transactions?.withdrawn?.length > 0 ? false : true}
                    onClick={() => setSelectedButton("withdrawn")}
                    style={{
                      justifyContent: 'center',
                      alignItems: "center",
                      marginTop: 5,
                      width: 105,
                      alignSelf: 'center',
                      backgroundColor: selectedButton === "withdrawn" ? 'black' : 'transparent',
                      borderWidth: selectedButton === "withdrawn" ? 0 : 1,
                      borderColor: "black",
                      color: selectedButton === "withdrawn" ? 'white' : 'black',
                      fontSize: 10
                    }}
                  >
                    {`withdrawn (${userData?.transactions?.withdrawn?.length})`}
                  </Button>

                  <Button
                    size="medium"
                    variant='outlined'
                    disabled={userData?.transactions?.onhold?.length > 0 ? false : true}
                    onClick={() => setSelectedButton("onhold")}
                    style={{
                      justifyContent: 'center',
                      alignItems: "center",
                      marginTop: 5,
                      width: 100,
                      alignSelf: 'center',
                      backgroundColor: selectedButton === "onhold" ? 'black' : 'transparent',
                      borderWidth: selectedButton === "onhold" ? 0 : 1,
                      borderColor: "black",
                      color: selectedButton === "onhold" ? 'white' : 'black',
                      fontSize: 10
                    }}
                  >
                    {`onhold (${userData?.transactions?.onhold?.length})`}
                  </Button>

                  <Button
                    size="medium"
                    variant='outlined'
                    disabled={userData?.transactions?.declined?.length > 0 ? false : true}
                    onClick={() => setSelectedButton("declined")}
                    style={{
                      justifyContent: 'center',
                      alignItems: "center",
                      marginTop: 5,
                      width: 100,
                      alignSelf: 'center',
                      backgroundColor: selectedButton === "declined" ? 'black' : 'transparent',
                      borderWidth: selectedButton === "declined" ? 0 : 1,
                      borderColor: "black",
                      color: selectedButton === "declined" ? 'white' : 'black',
                      fontSize: 10
                    }}
                  >
                    {`declined (${userData?.transactions?.declined?.length})`}
                  </Button>

                  <Button
                    size="medium"
                    variant='outlined'
                    disabled={userData?.transactions?.upcomming?.length > 0 ? false : true}
                    onClick={() => setSelectedButton("upcomming")}
                    style={{
                      justifyContent: 'center',
                      alignItems: "center",
                      marginTop: 5,
                      width: 115,
                      alignSelf: 'center',
                      backgroundColor: selectedButton === "upcomming" ? 'black' : 'transparent',
                      borderWidth: selectedButton === "upcomming" ? 0 : 1,
                      borderColor: "black",
                      color: selectedButton === "upcomming" ? 'white' : 'black',
                      fontSize: 10
                    }}
                  >
                    {`upcomming (${userData?.transactions?.upcomming?.length})`}
                  </Button>

                  <Button
                    size="medium"
                    variant='outlined'
                    disabled={userData?.transactions?.cancelled?.length > 0 ? false : true}
                    onClick={() => setSelectedButton("cancelled")}
                    style={{
                      justifyContent: 'center',
                      alignItems: "center",
                      marginTop: 5,
                      width: 100,
                      alignSelf: 'center',
                      backgroundColor: selectedButton === "cancelled" ? 'red' : 'transparent',
                      borderWidth: selectedButton === "cancelled" ? 0 : 1,
                      borderColor: selectedButton === "cancelled" ? 'transparent' : 'red',
                      color: selectedButton === "cancelled" ? 'white' : 'red',
                      fontSize: 10
                    }}
                  >
                    {`cancelled (${userData?.transactions?.cancelled?.length})`}
                  </Button>
                </Grid>
              </Box>
              <Divider sx={{ marginTop: 2 }} />
              <Scrollbar>

                <TableContainer sx={{ minWidth: 800, maxHeight: 350 }}>
                  <Table>
                    <UserListHead
                      headLabel={TABLE_HEAD_TRANSACTION}
                      rowCount={selectedList.length}
                      textStyle={{ fontSize: 11 }}
                    />
                    <TableBody>
                      {selectedList.map((row) => {
                        const {
                          id,
                          start_date,
                          end_date,
                          amount,
                          status,
                        } = row;

                        return (
                          <TableRow
                            hover
                            onClick={() => { }}
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell scope="row" padding="normal" style={{ width: 125 }}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                                style={{ width: 125 }}
                              >
                                <Avatar alt={userData?.user?.name} src={userData?.user?.avatar + '?v=' + Date.now()} />
                                <Box style={{ display: 'flex', flexDirection: 'column', width: 125 }}>
                                  <Typography fontSize={12} variant="subtitle2" flexWrap={'wrap'}>
                                    {userData?.user?.name}
                                  </Typography>
                                </Box>
                              </Stack>
                            </TableCell>

                            <TableCell align="left" style={{ maxWidth: 185 }}>
                              <Typography fontSize={13} noWrap>{userData?.user?.email}</Typography>
                            </TableCell>

                            <TableCell align="left" style={{ maxWidth: 125 }}>
                              <Typography fontSize={13} >
                                {start_date ? moment(start_date).format("DD MMM YYYY") : 'N/A'}
                              </Typography>
                            </TableCell>

                            <TableCell align="left" style={{ maxWidth: 125 }}>
                              <Typography fontSize={13} >
                                {end_date ? moment(end_date).format("DD MMM YYYY") : 'N/A'}
                              </Typography>
                            </TableCell>
                            <TableCell align="left" style={{ maxWidth: 125, color: selectedButton === "cancelled" ? 'red' : 'black' }}>
                              <Typography fontSize={13} variant="subtitle2" flexWrap={'wrap'}>
                                {amount ? selectedButton === "cancelled" ? `- ${amount}` : amount : 'N/A'}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>


              <Divider sx={{ marginTop: 2 }} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: 2
                }}
              >
                <LoadingButton
                  sx={{
                    marginRight: 1,
                    fontSize: 11
                  }}
                  onClick={handleCloseTransactionModal}
                  variant="outlined"
                >
                  {t('EditProfile.buttons.2')}
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Modal>

        {/* REQUEST DETAILS */}
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
          open={OpenTransactionModal2}
          onClose={handleCloseTransactionModal2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle, style, maxWidth: '80%' }} className="admin_modal_pa">
            <Box p={3} flexDirection={'column'} display={'flex'} flex={1}>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800, maxHeight: 350 }}>
                  <Table>
                    <UserListHead
                      headLabel={TABLE_HEAD_TRANSACTION3}
                      rowCount={list2.length}
                      textStyle={{ fontSize: 11 }}
                    />
                    <TableBody>
                      {list2.map((row) => {
                        const {
                          id,
                          requested_amount,
                          approved,
                          hold,
                          declined,
                          status,
                          user,
                        } = row;
                          console.log("🚀 ~ {list2.map ~ status:", status)

                        return (
                          <TableRow
                            hover
                            onClick={() => { }}
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell scope="row" padding="normal" style={{ width: 125 }}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                                style={{ width: 125 }}
                              >
                                <Avatar alt={userData?.user?.name} src={userData?.user?.avatar + '?v=' + Date.now()} />
                                <Box style={{ display: 'flex', flexDirection: 'column', width: 125 }}>
                                  <Typography fontSize={12} variant="subtitle2" flexWrap={'wrap'}>
                                    {userData?.user?.name}
                                  </Typography>
                                </Box>
                              </Stack>
                            </TableCell>

                            <TableCell align="left" style={{ maxWidth: 125 }}>
                              <Typography fontSize={13} >
                                {requested_amount || 'N/A'}
                              </Typography>
                            </TableCell>

                            <TableCell align="left" style={{ maxWidth: 125 }}>
                              <Typography fontSize={13} >
                                {hold || 'N/A'}
                              </Typography>
                            </TableCell>

                            <TableCell align="left" style={{ maxWidth: 125, color: selectedButton === "cancelled" ? 'red' : 'black' }}>
                              <Typography fontSize={13} flexWrap={'wrap'}>
                                {declined || 'N/A'}
                              </Typography>
                            </TableCell>

                            <TableCell align="left" style={{ maxWidth: 125, color: selectedButton === "cancelled" ? 'red' : 'black' }}>
                              <Typography fontSize={13} flexWrap={'wrap'}>
                                {approved || 'N/A'}
                              </Typography>
                            </TableCell>

                            <TableCell align="left" style={{ maxWidth: 120 }}>
                              <Label
                                style={{ height: 30, width: 100 }}
                                variant="ghost"
                                color={
                                  (status.toLowerCase() === 'approve' && 'success') ||
                                  (status.toLowerCase() === 'onhold' && 'info') ||
                                  (status.toLowerCase() === 'requested' && 'info') ||
                                  (status.toLowerCase() === 'pending' && 'info') ||
                                  (status.toLowerCase() === 'declined' && 'error') ||
                                  'error'
                                }
                              >
                                <Typography
                                  style={{
                                    textTransform: 'capitalize',
                                    fontWeight: 'bold',
                                    fontSize: 13
                                  }}
                                >
                                  {status}
                                </Typography>
                              </Label>
                            </TableCell>

                            <TableCell align="left" style={{ maxWidth: 155, minWidth: 120 }}>
                              {permission.includes('change status') && (
                                <>
                                  <Tooltip title="Approve">
                                    <Button
                                      size="small"
                                      disabled={status.toLowerCase() === 'approve' || status.toLowerCase() === 'declined' ? true : false}
                                      onClick={() => { handleChangeStatusFunction('approve', id) }}
                                      style={{
                                        justifyContent: 'center',
                                        marginTop: 5,
                                        width: 30, // Less than 30
                                        height: 30, // Ensuring it's a square button
                                        minWidth: 30, // Ensuring minimum width is set to 30
                                        padding: 0, // Removing padding to fit the icon properly
                                        lineHeight: 1, // Ensuring the icon is centered
                                        alignSelf: 'center',
                                        marginRight: 5,
                                        borderColor: status.toLowerCase() === 'approve' || status.toLowerCase() === 'declined' ? "#bbbfbc" : pallete.success.main
                                      }}
                                      variant="outlined"
                                      component={RouterLink}
                                      to="#"
                                    >
                                      <Iconify style={{ fontSize: 17, color: status.toLowerCase() === 'approve' || status.toLowerCase() === 'declined' ? "#bbbfbc" : pallete.success.main }} icon="ic:round-check" />
                                    </Button>
                                  </Tooltip>

                                  <Tooltip title="Hold">
                                    <Button
                                      size="small"
                                      disabled={status.toLowerCase() === 'onhold' || status.toLowerCase() === 'approve' || status.toLowerCase() === 'declined' ? true : false}
                                      onClick={() => { handleChangeStatusFunction('onhold', id) }}
                                      style={{
                                        justifyContent: 'center',
                                        marginTop: 5,
                                        width: 30, // Less than 30
                                        height: 30, // Ensuring it's a square button
                                        minWidth: 30, // Ensuring minimum width is set to 30
                                        padding: 0, // Removing padding to fit the icon properly
                                        lineHeight: 1, // Ensuring the icon is centered
                                        alignSelf: 'center',
                                        marginRight: 5,
                                        borderColor: status.toLowerCase() === 'onhold' || status.toLowerCase() === 'approve' || status.toLowerCase() === 'declined' ? "#bbbfbc" : pallete.info.main
                                      }}
                                      variant="outlined"
                                      component={RouterLink}
                                      to="#"
                                    >
                                      <Iconify style={{ fontSize: 17, color: status.toLowerCase() === 'onhold' || status.toLowerCase() === 'approve' || status.toLowerCase() === 'declined' ? "#bbbfbc" : pallete.info.main }} icon="hugeicons:hold-01" />
                                    </Button>
                                  </Tooltip>

                                  <Tooltip title="Declined">
                                    <Button
                                      size="small"
                                      disabled={status.toLowerCase() === 'approve' || status.toLowerCase() === 'declined' ? true : false}
                                      onClick={() => { handleChangeStatusFunction('decline', id) }}
                                      style={{
                                        justifyContent: 'center',
                                        marginTop: 5,
                                        width: 30, // Less than 30
                                        height: 30, // Ensuring it's a square button
                                        minWidth: 30, // Ensuring minimum width is set to 30
                                        padding: 0, // Removing padding to fit the icon properly
                                        lineHeight: 1, // Ensuring the icon is centered
                                        alignSelf: 'center',
                                        marginRight: 5,
                                        borderColor: status.toLowerCase() === 'approve' || status.toLowerCase() === 'declined' ? "#bbbfbc" : pallete.error.main
                                      }}
                                      variant="outlined"
                                      component={RouterLink}
                                      to="#"
                                    >
                                      <Iconify style={{ fontSize: 18, color: status.toLowerCase() === 'approve' || status.toLowerCase() === 'declined' ? "#bbbfbc" : pallete.error.main }} icon="iconoir:cancel" />
                                    </Button>
                                  </Tooltip>
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>

              <Divider sx={{ marginTop: 2 }} />

              {alertSuccess && alertSuccess.visible && <Alert style={{ marginBottom: 20 }} severity="success">{alertSuccess.message}</Alert>}
              {alertError && alertError.visible && <Alert style={{ marginBottom: 20 }} severity="error">{alertError.message}</Alert>}

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: 2
                }}
              >
                <LoadingButton
                  sx={{
                    marginRight: 1,
                    fontSize: 11
                  }}
                  onClick={handleCloseTransactionModal2}
                  variant="outlined"
                >
                  {t('EditProfile.buttons.2')}
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Modal>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Wallets
          </Typography>
        </Stack>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
            className="top_column"
          >
            <Searchbar
              width={'100%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              className="width_full_property"
              placeholder={t('Agents.search')}
              onEnter={() => {
                setsearchLoading(true);
                setPage(1);
                getWallets(1);
              }}
            />

            <StatusDropDown
              placeholder={'Status'}
              wallet
              type={'transaction'}
              width={'25%'}
              setFunction={(val) => {
                setstatus(val);
              }}
            />

            <LoadingButton
              style={{ width: '17%', marginLeft: '2.5%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                setPage(1);
                getWallets(1);
              }}
              className="width_full_status"
            >
              {' '}
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  textStyle={{ fontSize: 12 }}
                  headLabel={TABLE_HEAD}
                  rowCount={list.length}
                />
                <TableBody>
                  {list.map((row) => {
                    const {
                      id,
                      user,
                      funds_available,
                      withdraw_requested,
                      onhold,
                      declined,
                      upcomming,
                      cancelled,
                      withdrawn,
                      transactions
                    } = row;
                    const disabled = transactions.available.length == 0 &&
                      transactions.withdraw_requested.length == 0 &&
                      transactions.withdrawn.length == 0 &&
                      transactions.onhold.length == 0 &&
                      transactions.declined.length == 0 &&
                      transactions.upcomming.length == 0 &&
                      transactions.cancelled.length == 0

                    return (
                      <TableRow
                        hover
                        onClick={() => { }}
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell scope="row" padding="normal" style={{ width: 145 }}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            style={{ width: 145 }}
                          >
                            <Avatar alt={user?.name} src={user?.avatar + '?v=' + Date.now()} />
                            <Box style={{ display: 'flex', flexDirection: 'column', width: 145 }}>
                              <Typography fontSize={11} variant="subtitle2" flexWrap={'wrap'}>
                                {user?.name === "undefined" ? 'N/A' : user?.name}
                              </Typography>
                            </Box>
                          </Stack>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: 160 }}>
                          <Typography fontSize={11} noWrap>{user?.email === "undefined" ? 'N/A' : user?.email}</Typography>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: 135 }}>
                          <Typography fontSize={11} noWrap>{funds_available ? formatNumberWithCommas(funds_available) : 'N/A'}</Typography>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: 135 }}>
                          <Typography fontSize={11} noWrap>{withdraw_requested ? formatNumberWithCommas(withdraw_requested) : 'N/A'}</Typography>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: 135 }}>
                          <Typography fontSize={11} noWrap>{onhold ? formatNumberWithCommas(onhold) : 'N/A'}</Typography>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: 135 }}>
                          <Typography fontSize={11} noWrap>{declined ? formatNumberWithCommas(declined) : 'N/A'}</Typography>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: 135 }}>
                          <Typography fontSize={11} noWrap>{upcomming ? formatNumberWithCommas(upcomming) : 'N/A'}</Typography>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: 135 }}>
                          <Typography fontSize={11} noWrap>{cancelled ? formatNumberWithCommas(cancelled) : 'N/A'}</Typography>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: 135 }}>
                          <Typography fontSize={11} noWrap>{withdrawn ? formatNumberWithCommas(withdrawn) : 'N/A'}</Typography>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: 155, }}>
                          {permission.includes('transactions details') && (
                            <Button
                              disabled={disabled}
                              size="small"
                              onClick={() => openTransactionModal(row)}
                              style={{
                                justifyContent: 'flex-start',
                                marginTop: 5,
                                width: 120,
                                alignSelf: 'center',
                                marginRight: 10,
                                fontSize: 11
                              }}
                              variant="outlined"
                              component={RouterLink}
                              to="#"
                            >
                              transactions
                            </Button>
                          )}

                          {permission.includes('pending request') && (
                            <Button
                              disabled={(withdraw_requested > 0 || onhold > 0) ? false : true}
                              size="small"
                              onClick={() => openTransactionModal2(row)}
                              style={{
                                justifyContent: 'flex-start',
                                marginTop: 5,
                                width: 120,
                                alignSelf: 'center',
                                marginRight: 10,
                                fontSize: 11
                              }}
                              variant="outlined"
                              component={RouterLink}
                              to="#"
                            >
                              Pending requests
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isUserNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={11} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={search} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && !searchLoading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        {!loading && !searchLoading && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page >
  );
}
