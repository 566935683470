import React, { useState } from 'react';
import { Box, Button, Typography, backdropClasses } from '@mui/material';

import { api } from 'src/Api';
import RoleChangeDropdown from 'src/layouts/dashboard/RoleChangeDropDown';

export default function ChangeRoleModal({ handleClose, user, refreshAdmin }) {
  function AssignRole(roleSelected, userId) {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('user_id', userId);
    formdata.append('role_id', roleSelected);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.assignRole, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          refreshAdmin();
        }
      })
      .catch((error) => console.error(error));
  }
  return (
    <Box p={3} flexDirection={'column'} display={'flex'} flex={1}>
      <Typography py={3} align="center" variant="h5">
        Change User Role
      </Typography>
      <RoleChangeDropdown
        dontAppend={true}
        alreadyRole={user?.role}
        fullWidth={true}
        setRole={(roleSelected) => {
          AssignRole(roleSelected, user?.id);
        }}
      />
      <Button style={{ marginTop: 10 }} color="primary" variant="outlined" onClick={handleClose}>
        Close
      </Button>
    </Box>
  );
}
