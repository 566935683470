import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import GooglePlaces from 'src/components/GooglePlaces';
import Slider from '@mui/material/Slider';
import { ChangePropertyModal } from '../sections/@dashboard/user';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Box,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  CircularProgress,
  Drawer,
  IconButton,
  Pagination,
  Rating
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PropertyDetails } from '../sections/@dashboard/products';
import pallete from '../theme/palette';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';
import { api } from '../Api';
import { Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";


function valuetext(value) {
  return `${value}°C`;
}

export default function Properties() {
  const { t } = useTranslation();
  const TABLE_HEAD = [
    { id: 'address', label: t('Property.table.1'), alignRight: false },
    { id: 'price', label: t('Property.table.2'), alignRight: false },
    { id: 'spaceType', label: 'Type', alignRight: false },
    { id: 'user', label: 'Owner', alignRight: false },
    { id: 'area', label: 'Area', alignRight: false },
    { id: 'status', label: t('Property.table.5'), alignRight: false },
    { id: 'action', label: t('Property.table.6'), alignRight: false }
  ];

  const [permission, setpermission] = useState([]);
  const requirement = ['visible', 'change status'];

  function checkRender() {
    let check = 'properties';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );
    localPerm[0].permission_types.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  useEffect(() => {
    checkRender();
  }, []);

  const detailClose = () => {
    setDetail(false);
  };

  const [list, setlist] = useState([]);
  const [detail, setDetail] = React.useState(false);
  const [loading, setloading] = useState(true);
  const [price, setprice] = React.useState([0, 50000]);
  const [rating, setRating] = React.useState(0);

  const handleChange = (newValue, type) => {
    type == 'price' && setprice(newValue);
  };

  function getProperties(pagenumber) {
    setloading(true);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('host', 'admin');
    pagenumber && formdata.append('page', Encryption(pagenumber, process.env.REACT_APP_SECRET_APP));

    search.length > 0 &&
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_APP));

    price && formdata.append('price_from', Encryption(price[0], process.env.REACT_APP_SECRET_APP));
    price && formdata.append('price_to', Encryption(price[1], process.env.REACT_APP_SECRET_APP));
    dateRangeStore !== null && formdata.append('date_from', dayjs(dateRangeStore.startDate).format('YYYY-MM-DD'));
    dateRangeStore !== null && formdata.append('date_to', dayjs(dateRangeStore.endDate).format('YYYY-MM-DD'));
    rating && formdata.append('rating', rating);
    if (address && address.lat && address.lon) {
      formdata.append('lat', address?.lat);
      formdata.append('lon', address?.lon);
      address?.lat && address?.lon && formdata.append('radius', '25000');
    }

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.getProperties, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);

        if (result.success === true) {
          console.log("🚀 ~ .then ~ result:", result)

          setlist(result.data);
          setOpenDrawer(false);
          result?.count ? setpagesCount(Math.ceil(result?.count / 12)) : setpagesCount(1);
        } else {
          console.log('result', result)
          setlist([]);
          setpagesCount(0);
        }
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setsearchLoading(false);
        setloading(false);
      });
  }

  const refresh = () => {
    setDetail(false);
    getProperties(page);
  };

  useEffect(() => {
    getProperties();
  }, []);

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [business, setbusiness] = useState(null);

  function openModal(row) {
    setbusiness(row);
    setDetail(true);
  }

  const isBusinessFound = list.length === 0;
  const style = {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const boxStyle = {
    minWidth: '70%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    border: '2px solid',
    borderColor: pallete.primary.main
  };

  function closeandOpen() {
    setDetail(false);
  }

  const [search, setsearch] = useState('');
  const [searchLoading, setsearchLoading] = useState(false);

  const [address, setaddress] = useState(null);
  const tableRef = React.createRef();

  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);
  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    getProperties(value);
  };

  const [statusModal, setstatusModal] = useState(false);

  function openStatusModal(row) {
    setbusiness(row);
    setstatusModal(true);
  }

  const refreshAdmin = () => {
    setstatusModal(false);
    getProperties(page);
  };

  useEffect(() => {
    if (search.length == 0) {
      list.length = 0;
      getProperties();
    }
  }, [search]);

  useEffect(() => {
    list.length = 0;
    getProperties();
  }, [address]);

  const [dateRangeStore, setDateRangeStore] = useState(null);

  const handleStartDateChange = (newDate) => {
    setDateRangeStore((prevDateRange) => ({
      ...prevDateRange,
      startDate: newDate.toDate(),
    }));
  };

  const handleEndDateChange = (newDate) => {
    setDateRangeStore((prevDateRange) => ({
      ...prevDateRange,
      endDate: newDate.toDate(),
    }));
  };

  useEffect(() => {
    if (dateRangeStore == null) {
      setprice([0, 50000]);
      setRating(0);
      setPage(1);
      getProperties(1);
    }
  }, [dateRangeStore])


  return (
    <Page title={`Properties | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        {/* PROPERTY DETAIL MODAL */}
        <Modal style={style} open={detail} onClose={detailClose}>
          <Box
            sx={{ ...boxStyle, p: 0, minWidth: '70%', minHeight: '80%' }}
            className="admin_modal_pa"
          >
            {/* PROPERTY DETAIL */}
            <PropertyDetails
              handleClose={detailClose}
              details={business}
              refresh={refresh}
              closeandOpen={closeandOpen}
            />
          </Box>
        </Modal>
        {/* CHANGE STATUS MODAL */}
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
          open={statusModal}
          onClose={() => {
            setstatusModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              maxWidth: '40%',
              maxHeight: '90%',
              bgcolor: 'background.paper',
              boxShadow: 24,
              overflow: 'auto',
              border: '2px solid',
              borderColor: pallete.primary.main
              // style
            }}
            className="admin_modal_pa"
          >
            <ChangePropertyModal
              property={business}
              handleClose={() => {
                setstatusModal(false);
              }}
              refreshAdmin={refreshAdmin}
            />
          </Box>
        </Modal>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            {t('Property.name')}
          </Typography>
        </Stack>

        {/* DRAWER FILTER */}
        <Drawer
          classes={{
            paper: 'width_drawer'
          }}
          anchor={'right'}
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false);
          }}
        >
          <Box pb={2} className="cross_drawer">
            <IconButton
              aria-label="close"
              style={{
                position: 'absolute',
                right: 5,
                marginBottom: 10,
                marginRight: 10
              }}
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box
            mt={3}
            p={1}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flexWrap: 'wrap',
              width: '90%',
              alignSelf: 'center'
            }}
          >
            <Typography style={{ marginTop: 5, fontSize: 16 }}>Price</Typography>

            <Slider
              getAriaLabel={() => 'Temperature range'}
              value={price}
              onChange={(e, val) => {
                handleChange(val, 'price');
              }}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              min={0}
              max={50000}
              defaultValue={1000000}
              step={100}
              size="small"
            />
          </Box>

          <Box
            p={1}
            style={{
              flexDirection: 'column',
              display: 'flex',
              flexWrap: 'wrap',
              width: '90%',
              alignSelf: 'center'
            }}
          >
            <Typography style={{ marginTop: 5, fontSize: 16, marginBottom: 5 }}>Calendar</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Start Date"
                  value={dateRangeStore?.startDate && dayjs(dateRangeStore?.startDate)}
                  onChange={handleStartDateChange}
                />
                <DatePicker
                  label="End Date"
                  value={dateRangeStore?.endDate && dayjs(dateRangeStore?.endDate)}
                  onChange={handleEndDateChange}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>

          <Box
            p={1}
            style={{
              flexDirection: 'column',
              display: 'flex',
              flexWrap: 'wrap',
              width: '90%',
              alignSelf: 'center'
            }}
          >
            <Typography style={{ marginTop: 5, fontSize: 16, marginBottom: 5 }}>Rating</Typography>
            <Rating
              name="simple-controlled"
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
              size="large"
            />
          </Box>

          <Box style={{ width: '90%', alignSelf: 'center', marginTop: 20, marginBottom: 20 }}>
            <Button
              onClick={(event) => {
                setDateRangeStore(null)
              }}
              variant="outlined"
              component={RouterLink}
              to="#"
              fullWidth
              style={{ height: 50, marginBottom: 15 }}
            >
              Clear All
            </Button>
            <Button
              onClick={(event) => {
                list.length = 0;
                setOpenDrawer(false);
                // setsearchLoading(true);
                setPage(1);
                getProperties(1);
              }}
              variant="contained"
              component={RouterLink}
              to="#"
              fullWidth
              style={{ height: 50 }}
            >
              Apply Filters
            </Button>
          </Box>
        </Drawer>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between'
            }}
            className="top_options"
          >
            <Searchbar
              width={'50%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              placeholder={t('Agents.search') + '...'}
              onEnter={() => {
                setsearchLoading(true);
                getProperties();
              }}
            />

            {/* GOOGLE PLACES */}
            <GooglePlaces
              width={'35%'}
              onResult={(val) => {
                setaddress(val);
              }}
              regions={false}
              placeholder={t('Property.loc')}
            />

            <LoadingButton
              style={{ width: '4%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                setPage(1);
                getProperties(1);
              }}
              className="width_full"
            >
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
            <LoadingButton
              style={{ width: '4%' }}
              variant="contained"
              //   loading={searchLoading}
              onClick={() => {
                setOpenDrawer(true);
              }}
              className="width_full"
            >
              <Iconify icon="ion:filter" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table ref={tableRef}>
                <UserListHead headLabel={TABLE_HEAD} rowCount={list.length} />
                <TableBody>
                  {list.map((row, index) => {
                    const {
                      id,
                      seen,
                      price,
                      status,
                      images,
                      location,
                      space_type,
                      sub_space_type,
                      user,
                      length,
                      width,
                      height
                    } = row;

                    return (
                      <TableRow
                        style={{
                          cursor: 'pointer',
                          backgroundColor: seen == '0' && 'rgba(253,208,151,0.2)'
                        }}
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell scope="row" padding="normal" style={{ width: 300 }}>
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Avatar
                              alt={row.title}
                              src={
                                images?.length > 0
                                  ? images[0]?.uri
                                  : 'https://api.pomillions.com/public/storage/business/businessplaceholder.png'
                              }
                            />
                            <div className="one-line-ellipsis">{location}</div>
                          </Stack>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: 140 }}>
                          <Typography variant="subtitle2">
                            {'ZAR'} {price}
                          </Typography>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: 140 }}>
                          <Typography variant="subtitle2">{space_type || 'N/A'}</Typography>
                          {sub_space_type && (
                            <Typography variant="subtitle2" style={{ fontSize: 12 }}>
                              ({sub_space_type || 'N/A'})
                            </Typography>
                          )}
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: 140 }}>
                          <Typography variant="subtitle2">{user?.name}</Typography>
                        </TableCell>

                        <TableCell align="left" style={{ minWidth: 140 }}>
                          <Typography variant="subtitle2">
                            {length || 'N/A'} . {width || 'N/A'} . {height || 'N/A'}
                          </Typography>
                        </TableCell>

                        <TableCell align="left" style={{ maxWidth: 150 }}>
                          <Label
                            style={{ height: 30, paddingLeft: 30, paddingRight: 30, width: 130 }}
                            variant="ghost"
                            color={(status === 'Deleted' && 'error') || 'success'}
                          >
                            <Typography
                              style={{
                                textTransform: 'capitalize',
                                fontWeight: 'bold',
                                fontSize: 12
                              }}
                            >
                              {status}
                            </Typography>
                          </Label>
                        </TableCell>

                        {/* ACTIONS */}
                        <TableCell style={{ position: 'relative', minHeight: 400, width: '200px' }}>
                          <Box
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start'
                            }}
                          >
                            {permission.includes('visible') && (
                              <Button
                                startIcon={<Iconify color={pallete.primary.main} icon="gg:notes" />}
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  openModal(row);
                                }}
                                variant="outlined"
                                size="small"
                                color="primary"
                                component={RouterLink}
                                to="#"
                                style={{
                                  width: 135,
                                  justifyContent: 'flex-start',
                                  marginTop: 5
                                }}
                              >
                                Details
                              </Button>
                            )}
                            {permission.includes('change status') && (
                              <Button
                                size="small"
                                onClick={() => openStatusModal(row)}
                                style={{
                                  justifyContent: 'flex-start',
                                  marginTop: 5,
                                  width: 135
                                }}
                                variant="outlined"
                                component={RouterLink}
                                to="#"
                                startIcon={
                                  <Iconify
                                    color={pallete.primary.main}
                                    icon="mdi:swap-horizontal-bold"
                                  />
                                }
                              >
                                Change Status
                              </Button>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isBusinessFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={search} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        {!loading && !searchLoading && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
