import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import red from '@material-ui/core/colors/red';
// import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

// const defaultMaterialTheme = createMuiTheme({
//   palette: {
//     primary: red
//   }
// });

function BasicTimePicker({ value, onChange, width, label }) {
  return (
    <ThemeProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <TimePicker
          clearable
          label={label}
          inputVariant="outlined"
          value={value}
          onChange={(e) => {
            console.log(e);
            onChange(e);
          }}
          style={{ marginBottom: 20, width: width }}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default BasicTimePicker;
